import { renderMergedColumn } from '../features/reports-new/formatData';
import { sortString } from './sorterFunc';

export const CONST_REPORT_TABS_COLUMNS = [
  {
    key: 'Summary',
    columns: [
      //add site column
      {
        title: 'Site',
        dataIndex: 'site',
        width: 70,
        blankWidth: 70,
        sorter: true,
        render: false,
      },
      {
        title: 'Acct Type',
        dataIndex: 'accountType',
        width: 100,
        blankWidth: 100,
        sorter: true,
        render: false,
        toggleModal: true,
      },
      {
        title: 'Sales',
        dataIndex: 'totalSales',
        width: 130,
        blankWidth: 130,
        sorter: true,
        render: (value) => value === null || value === 0? '$0.00' : value && `$${value.toLocaleString('zh',{minimumFractionDigits:2})}`,
        className: 'tit-right'
      },
      {
          title: 'Pieces',
          dataIndex: 'totalPiece',
          width: 100,
          blankWidth: 100,
          sorter: true,
          render: false,
          className: 'tit-right'
      },
      // {
      //   title: 'Cases',
      //   dataIndex: 'totalCases',
      //   width: 90,
      //   blankWidth: 90,
      //   sorter: true,
      //   render: false,
      //   className: 'tit-right'
      // },
      // {
      //   title: 'Eaches',
      //   dataIndex: 'totalEaches',
      //   width: 90,
      //   blankWidth: 90,
      //   sorter: true,
      //   render: false,
      //   className: 'tit-right'
      // },
      {
        title: 'Avg Sell',
        dataIndex: 'averageSell',
        width: 95,
        blankWidth: 95,
        sorter: false,
        render: (value) => value === null || value === 0? '$0.00' : value && `$${value.toLocaleString('zh',{minimumFractionDigits:2})}`,
        className: 'tit-right'
      },
      {
        title: 'Sales %',
        dataIndex: 'salesPercentage',
        width: 90,
        blankWidth: 90,
        sorter: false,
        render: (value) => value === null || value === 0? '0.00%' : value && `${value.toLocaleString('zh',{minimumFractionDigits:2})}%`,
        className: 'tit-right'
      },
      {
        title: '# of Orders',
        dataIndex: 'noOfOrders',
        width: 110,
        blankWidth: 110,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      {
        title: 'Orders on Hold',
        dataIndex: 'holdOrders',
        width: 110,
        blankWidth: 110,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      {
        title: 'Offday Sales',
        dataIndex: 'offDaySales',
        width: 125,
        blankWidth: 125,
        sorter: true,
        // render: (value) => value === null || value === 0? '' : value && `$${value.toLocaleString('zh',{minimumFractionDigits:2})}`,
        toggleModal: true,
        className: 'tit-right',
      },
      {
        title: 'Offday Cases',
        dataIndex: 'offDayCases',
        width: 110,
        blankWidth: 110,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      {
        title: 'Offday Eaches',
        dataIndex: 'offDayEaches',
        width: 110,
        blankWidth: 110,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      {
        title: 'Offday Orders',
        dataIndex: 'offDayOrders',
        width: 110,
        blankWidth: 110,
        sorter: true,
        render: (value) => value +'' +'\xa0\xa0\xa0\xa0',//add some space for last column if last column is numberic, otherwise the value is very closer to vertical scrollbar
        className: 'tit-right'
      },
      // {
      //   title: 'GP Percentages',
      //   dataIndex: 'gpPercentages',
      //   width: 130,
      //   blankWidth: 130,
      //   sorter: false,
      //   render: (value) => value && `${value.toLocaleString()}%`,
      // },
    ],
    customerColumns: [
      {
        title: 'Cust Name',
        dataIndex: 'customerName',
        width: 170,
        blankWidth: 110,
        sorter: true,
        render: false,
      },
      {
        title: 'Cust #',
        dataIndex: 'customerNumber',
        width: 110,
        blankWidth: 110,
        sorter: true,
        render: false,
        toggleModal: true,
      },
      {
        title: '# Of Orders',
        dataIndex: 'noOfOrders',
        width: 110,
        blankWidth: 110,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      {
        title: 'Sales',
        dataIndex: 'totalSales',
        width: 130,
        blankWidth: 130,
        sorter: true,
        render: (value) => value === null || value === 0? '$0.00' : value && `$${value.toLocaleString('zh',{minimumFractionDigits:2})}`,
        className: 'tit-right'
      },
      {
        title: 'Cases',
        dataIndex: 'totalCases',
        width: 100,
        blankWidth: 100,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      {
        title: 'Eaches',
        dataIndex: 'totalEaches',
        width: 100,
        blankWidth: 100,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      {
        title: 'Off Day',
        dataIndex: 'offDay',
        width: 90,
        blankWidth: 90,
        sorter: true,
        render: false,
      },
      {
        title: 'Delv Days',
        dataIndex: 'deliveryDays',
        width: 130,
        blankWidth: 110,
        sorter: true,
        render: false,
      },
      // {
      //   title: 'SC Name',
      //   dataIndex: 'scName',
      //   width: 170,
      //   blankWidth: 150,
      //   sorter: true,
      //   render: false,
      // },
      {
        title: 'SC',
        dataIndex: 'scId',
        width: 180,
        blankWidth: 150,
        sorter: true,
        render: (value,record) =>{
         return renderMergedColumn(record.scName,record.scId,'-');
        },
      },
      {
        title: 'DSM',
        dataIndex: 'district',
        width: 180,
        blankWidth: 150,
        sorter: true,
        render: (value,record) =>{
          return renderMergedColumn(record.dsmName,record.district,'-');
         },
      },
      // {
      //   title: 'DSM Name',
      //   dataIndex: 'dsmName',
      //   width: 150,
      //   blankWidth: 110,
      //   sorter: true,
      //   render: false,
      // },
      {
        title: 'RSM',
        dataIndex: 'region',
        width: 180,
        blankWidth: 150,
        sorter: true,
        render: (value,record) =>{
          return renderMergedColumn(record.rsmName,record.region,'-');
         },
      },
      // {
      //   title: 'RSM Name',
      //   dataIndex: 'rsmName',
      //   width: 150,
      //   blankWidth: 110,
      //   sorter: true,
      //   render: false,
      // },
    ],
    orderColumns: [
      {
        title: 'Order #',
        dataIndex: 'orderNumber',
        width: 180,
        blankWidth: 100,
        sorter: true,
        render: false,
      },
      {
        title: 'Sales',
        dataIndex: 'orderTotalSales',
        width: 120,
        blankWidth: 120,
        sorter: true,
        className: 'tit-right',
        render: (value) => value === null || value === 0? '$0.00' : value && `$${value.toLocaleString('zh',{minimumFractionDigits:2})}`,
      },
      {
        title: 'Cases',
        dataIndex: 'orderTotalCases',
        width: 110,
        blankWidth: 110,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      {
        title: 'Eaches',
        dataIndex: 'orderTotalEaches',
        width: 110,
        blankWidth: 110,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      {
        title: 'Order Type',
        dataIndex: 'orderType',
        width: 120,
        blankWidth: 120,
        sorter: true,
        render: false,
      },
      {
        title: 'Off Day',
        dataIndex: 'offDay',
        width: 100,
        blankWidth: 100,
        sorter: true,
        render: false,
      },
      {
        title: 'Delv Days',
        dataIndex: 'deliveryDays',
        width: 130,
        blankWidth: 110,
        sorter: false,
        render: false,
      },
    ],
  },
  {
    key: 'Orders',
    columns:[
      {
        title: 'OrderCh Type',
        dataIndex: 'orderChannelType',
        width: 180,
        blankWidth: 180,
        sorter: true,
        render: false,
        toggleModal: true,
      },
      {
        title: '# of Cust',
        dataIndex: 'noOfCust',
        width: 135,
        blankWidth: 135,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      {
        title: '# of Orders',
        dataIndex: 'noOfOrders',
        width: 135,
        blankWidth: 135,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      // { 
      //   title: 'Remote Order Entry Mode',
      //   dataIndex: 'remoteOrderEntryMode',
      //   width: 150,
      //   blankWidth: 130,
      //   sorter: false,
      //   render: false,
      // },
      {
        title: 'Off Day Orders',
        dataIndex: 'offDayOrders',
        width: 135,
        blankWidth: 135,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      {
        title: 'Sales',
        dataIndex: 'totalSales',
        width: 150,
        blankWidth: 150,
        sorter: true,
        render: (value) => value === null || value === 0? '$0.00' : value && `$${value.toLocaleString('zh',{minimumFractionDigits:2})}`,
        className: 'tit-right'
      },
      {
        title: 'Cases',
        dataIndex: 'totalCases',
        width: 110,
        blankWidth: 110,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      {
        title: 'Eaches',
        dataIndex: 'totalEaches',
        width: 110,
        blankWidth: 110,
        sorter: true,
        render: (value) => value +'' +'\xa0\xa0\xa0\xa0',
        className: 'tit-right'
      },
    ],
    accountColumns:[
      //add site column
      {
        title: 'Site',
        dataIndex: 'site',
        width: 70,
        blankWidth: 70,
        sorter: true,
        render: false,
      },
      {
        title: 'Acct Type',
        dataIndex: 'accountType',
        width: 100,
        blankWidth: 100,
        sorter: true,
        render: false,
        toggleModal: true,
      },
      {
        title: 'Sales',
        dataIndex: 'totalSales',
        width: 130,
        blankWidth: 130,
        sorter: true,
        render: (value) => value === null || value === 0? '$0.00' : value && `$${value.toLocaleString('zh',{minimumFractionDigits:2})}`,
        className: 'tit-right'
      },
      {
        title: 'Pieces',
        dataIndex: 'totalPiece',
        width: 100,
        blankWidth: 100,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      // {
      //   title: 'Cases',
      //   dataIndex: 'totalCases',
      //   width: 90,
      //   blankWidth: 90,
      //   sorter: true,
      //   render: false,
      //   className: 'tit-right'
      // },
      // {
      //   title: 'Eaches',
      //   dataIndex: 'totalEaches',
      //   width: 90,
      //   blankWidth: 90,
      //   sorter: true,
      //   render: false,
      //   className: 'tit-right'
      // },
      {
        title: 'Avg Sell',
        dataIndex: 'averageSell',
        width: 95,
        blankWidth: 95,
        sorter: false,
        render: (value) => value === null || value === 0? '$0.00' : value && `$${value.toLocaleString('zh',{minimumFractionDigits:2})}`,
        className: 'tit-right'
      },
      {
        title: 'Sales %',
        dataIndex: 'salesPercentage',
        width: 90,
        blankWidth: 90,
        sorter: false,
        render: (value) => value === null || value === 0? '0.00%' : value && `${value.toLocaleString('zh',{minimumFractionDigits:2})}%`,
        className: 'tit-right'
      },
      {
        title: '# of Orders',
        dataIndex: 'noOfOrders',
        width: 110,
        blankWidth: 110,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      {
        title: 'Orders on Hold',
        dataIndex: 'holdOrders',
        width: 110,
        blankWidth: 110,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      {
        title: 'Offday Sales',
        dataIndex: 'offDaySales',
        width: 125,
        blankWidth: 125,
        sorter: true,
        // render: (value) => value === null || value === 0? '0.00' : value && `$${value.toLocaleString('zh',{minimumFractionDigits:2})}`,
        toggleModal: true,
        className: 'tit-right'
      },
      {
        title: 'Offday Cases',
        dataIndex: 'offDayCases',
        width: 110,
        blankWidth: 110,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      {
        title: 'Offday Eaches',
        dataIndex: 'offDayEaches',
        width: 110,
        blankWidth: 110,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      {
        title: 'Offday Orders',
        dataIndex: 'offDayOrders',
        width: 110,
        blankWidth: 110,
        sorter: true,
        render: (value) => value +'' +'\xa0\xa0\xa0\xa0',
        className: 'tit-right'
      },
      // {
      //   title: 'GP Percentages',
      //   dataIndex: 'gpPercentages',
      //   width: 130,
      //   blankWidth: 140,
      //   sorter: false,
      //   render: (value) => value && `${value.toLocaleString()}%`,
      // },
    ],
    orderColumns: [
      // {
      //   title: 'Large Hits',
      //   dataIndex: 'largeHits',
      //   width: null,
      //   blankWidth: null,
      //   sorter: false,
      //   render: false,
      //   renderBadge: true,
      //   className: "badge-item-col",
      // },
      // {
      //   title: 'Orders under Y Cases or X$',
      //   dataIndex: 'underXOrY',
      //   width: null,
      //   blankWidth: null,
      //   sorter: false,
      //   render: false,
      //   renderBadge: true,
      //   className: "badge-item-col",
      // },
      // {
      //   title: 'Offday Orders',
      //   dataIndex: 'offDay',
      //   width: null,
      //   blankWidth: null,
      //   sorter: false,
      //   render: false,
      //   renderBadge: true,
      //   className: "badge-item-col",
      // },
      {
        title: 'Order #',
        dataIndex: 'orderNumber',
        width: 180,
        blankWidth: 100,
        sorter: true,
        render: false,
        toggleModal: true,
      },
      {
        title: 'Site #',
        dataIndex: 'site',
        width: 70,
        blankWidth: 70,
        sorter: true,
        render: false,
      },
      {
        title: 'Cust #',
        dataIndex: 'customerNumber',
        width: 100,
        blankWidth: 100,
        sorter: true,
        render: false,
      },
      {
        title: 'Cust Name',
        dataIndex: 'customerName',
        width: 170,
        blankWidth: 110,
        sorter: true,
        render: false,
      },
      {
        title: 'Sales',
        dataIndex: 'orderTotalSales',
        width: 130,
        blankWidth: 130,
        sorter: true,
        className: 'tit-right',
        render: (value) => value === null || value === 0? '$0.00' : value && `$${value.toLocaleString('zh',{minimumFractionDigits:2})}`,
      },      
      // {
      //   title: 'GP $',
      //   dataIndex: 'gpSales',
      //   width: 80,
      //   blankWidth: 80,
      //   sorter: true,
      //   render: (value, record) => value && `$${value.toLocaleString()}`,
      // },
      // {
      //   title: 'GP %',
      //   dataIndex: 'gpPercentage',
      //   width: 80,
      //   blankWidth: 80,
      //   sorter: true,
      //   render: (value, record) => value && `${value.toLocaleString()}%`,
      // },
      {
        title: 'Cases',
        dataIndex: 'orderTotalCases',
        width: 90,
        blankWidth: 80,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      {
        title: 'Eaches',
        dataIndex: 'orderTotalEaches',
        width: 90,
        blankWidth: 80,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      {
        title:'Large Hits',
        dataIndex: 'largeHits',
        width: 80,
        blankWidth: 50,
        // sorter: true,
        render: (value, record) => value === 'Y'?'Yes':value === 'N'?'No':null,
      },
      {
        title:'Under X Or Y',
        dataIndex: 'underXOrY',
        width: 80,
        blankWidth: 50,
        // sorter: true,
        render: (value, record) => value === 'Y'?'Yes':value === 'N'?'No':null,
      },
      {
        title:'Off Day',
        dataIndex: 'offDay',
        width: 80,
        blankWidth: 50,
        // sorter: true,
        render: (value, record) => value === 'Y'?'Yes':value === 'N'?'No':null,
      },
      {
        title: 'Order Source',
        dataIndex: 'orderSource',
        width: 150,
        blankWidth: 120,
        sorter: true,
        render: false,
      },
      {
        title: 'Delv Days',
        dataIndex: 'deliveryDays',
        width: 130,
        blankWidth: 110,
        sorter: true,
        render: false,
      },
    ],
    customerColumns: [
      {
        title: 'Date',
        dataIndex: 'dateStamp',
        width: null,
        blankWidth: 100,
        sorter: (itemOne, itemTwo) => sortString(itemOne.dateStamp, itemTwo.dateStamp),
      },
      {
        title: 'Time',
        dataIndex: 'timeStamp',
        width: null,
        blankWidth: 100,
        sorter: (itemOne, itemTwo) => sortString(itemOne.timeStamp, itemTwo.timeStamp),
      },
      {
        title: 'Log',
        dataIndex: 'event',
        width: 370,
        blankWidth: 350,
        sorter: (itemOne, itemTwo) => sortString(itemOne.event, itemTwo.event),
      },
      {
        title: 'User ID',
        dataIndex: 'userId',
        width: 95,
        blankWidth: 100,
        sorter: (itemOne, itemTwo) => sortString(itemOne.userId, itemTwo.userId),
      },
      {
        title: 'Event Source',
        dataIndex: 'source',
        width: 130,
        blankWidth: 120,
        sorter: (itemOne, itemTwo) => sortString(itemOne.source, itemTwo.source),
      },
    ],
    // orderColumns: null,
  },
  {
    key: 'Future Orders',
    columns:[
      {
        title: 'OrderCh Type',
        dataIndex: 'orderChannelType',
        width: 180,
        blankWidth: 180,
        sorter: true,
        render: false,
        toggleModal: true,
      },
      {
        title: '# of Cust',
        dataIndex: 'noOfCust',
        width: 135,
        blankWidth: 135,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      {
        title: '# of Orders',
        dataIndex: 'noOfOrders',
        width: 110,
        blankWidth: 110,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      // { 
      //   title: 'Remote Order Entry Mode',
      //   dataIndex: 'remoteOrderEntryMode',
      //   width: 150,
      //   blankWidth: 130,
      //   sorter: false,
      //   render: false,
      // },
      {
        title: 'Off Day Orders',
        dataIndex: 'offDayOrders',
        width: 135,
        blankWidth: 135,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      {
        title: 'Sales',
        dataIndex: 'totalSales',
        width: 150,
        blankWidth: 150,
        sorter: true,
        className: 'tit-right',
        render: (value) => value === null || value === 0? '$0.00' : value && `$${value.toLocaleString('zh',{minimumFractionDigits:2})}`,
      },
      {
        title: 'Cases',
        dataIndex: 'totalCases',
        width: 110,
        blankWidth: 110,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      {
        title: 'Eaches',
        dataIndex: 'totalEaches',
        width: 110,
        blankWidth: 110,
        sorter: true,
        render: (value) => value +'' +'\xa0\xa0\xa0\xa0',
        className: 'tit-right'
      },
    ],
    accountColumns:[
      //add site column
      {
        title: 'Site',
        dataIndex: 'site',
        width: 70,
        blankWidth: 70,
        sorter: true,
        render: false,
      },
      {
        title: 'Acct Type',
        dataIndex: 'accountType',
        width: 100,
        blankWidth: 100,
        sorter: true,
        render: false,
        toggleModal: true,
      },
      {
        title: 'Sales',
        dataIndex: 'totalSales',
        width: 130,
        blankWidth: 130,
        sorter: true,
        className: 'tit-right',
        render: (value) => value === null || value === 0? '$0.00' : value && `$${value.toLocaleString('zh',{minimumFractionDigits:2})}`,
      },
      {
        title: 'Cases',
        dataIndex: 'totalCases',
        width: 90,
        blankWidth: 90,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      {
        title: 'Eaches',
        dataIndex: 'totalEaches',
        width: 90,
        blankWidth: 90,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      {
        title: 'Avg Sell',
        dataIndex: 'averageSell',
        width: 100,
        blankWidth: 100,
        sorter: false,
        className: 'tit-right',
        render: (value) => value === null || value === 0? '$0.00' : value && `$${value.toLocaleString('zh',{minimumFractionDigits:2})}`,
      },
      {
        title: 'Sales %',
        dataIndex: 'salesPercentage',
        width: 90,
        blankWidth: 90,
        sorter: false,
        className: 'tit-right',
        render: (value) => value === null || value === 0? '0.00%' : value && `${value.toLocaleString('zh',{minimumFractionDigits:2})}%`,
      },
      {
        title: '# of Orders',
        dataIndex: 'noOfOrders',
        width: 110,
        blankWidth: 110,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      {
        title: 'Orders on Hold',
        dataIndex: 'holdOrders',
        width: 140,
        blankWidth: 140,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      {
        title: 'Offday Sales',
        dataIndex: 'offDaySales',
        width: 125,
        blankWidth: 125,
        sorter: true,
        // render: (value) => value === null || value === 0? '0.00' : value && `$${value.toLocaleString('zh',{minimumFractionDigits:2})}`,
        toggleModal: true,
        className: 'tit-right'
      },
      {
        title: 'Offday Cases',
        dataIndex: 'offDayCases',
        width: 125,
        blankWidth: 125,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      {
        title: 'Offday Eaches',
        dataIndex: 'offDayEaches',
        width: 130,
        blankWidth: 130,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      {
        title: 'Offday Orders',
        dataIndex: 'offDayOrders',
        width: 125,
        blankWidth: 125,
        sorter: true,
        render: (value) => value +'' +'\xa0\xa0\xa0\xa0',
        className: 'tit-right'
      },
      // {
      //   title: 'GP Percentages',
      //   dataIndex: 'gpPercentages',
      //   width: 130,
      //   blankWidth: 140,
      //   sorter: false,
      //   render: (value) => value && `${value.toLocaleString()}%`,
      // },
    ],
    orderColumns: [
      // {
      //   title: 'Large Hits',
      //   dataIndex: 'largeHits',
      //   width: null,
      //   blankWidth: null,
      //   sorter: false,
      //   render: false,
      //   renderBadge: true,
      //   className: "badge-item-col",
      // },
      // {
      //   title: 'Orders under Y Cases or X$',
      //   dataIndex: 'underXOrY',
      //   width: null,
      //   blankWidth: null,
      //   sorter: false,
      //   render: false,
      //   renderBadge: true,
      //   className: "badge-item-col",
      // },
      // {
      //   title: 'Offday Orders',
      //   dataIndex: 'offDay',
      //   width: null,
      //   blankWidth: null,
      //   sorter: false,
      //   render: false,
      //   renderBadge: true,
      //   className: "badge-item-col",
      // },
      {
        title: 'Order #',
        dataIndex: 'orderNumber',
        width: 180,
        blankWidth: 100,
        sorter: true,
        render: false,
        toggleModal: true,
      },
      {
        title: 'Site #',
        dataIndex: 'site',
        width: 80,
        blankWidth: 80,
        sorter: true,
        render: false,
      },
      {
        title: 'Cust #',
        dataIndex: 'customerNumber',
        width: 100,
        blankWidth: 100,
        sorter: true,
        render: false,
      },
      {
        title: 'Cust Name',
        dataIndex: 'customerName',
        width: 170,
        blankWidth: 110,
        sorter: true,
        render: false,
      },
      {
        title: 'Sales',
        dataIndex: 'orderTotalSales',
        width: 130,
        blankWidth: 130,
        sorter: true,
        className: 'tit-right',
        render: (value) => value === null || value === 0? '$0.00' : value && `$${value.toLocaleString('zh',{minimumFractionDigits:2})}`,
      },      
      // {
      //   title: 'GP $',
      //   dataIndex: 'gpSales',
      //   width: 80,
      //   blankWidth: 80,
      //   sorter: true,
      //   render: (value, record) => value && `$${value.toLocaleString()}`,
      // },
      // {
      //   title: 'GP %',
      //   dataIndex: 'gpPercentage',
      //   width: 80,
      //   blankWidth: 80,
      //   sorter: true,
      //   render: (value, record) => value && `${value.toLocaleString()}%`,
      // },
      {
        title: 'Cases',
        dataIndex: 'orderTotalCases',
        width: 100,
        blankWidth: 80,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      {
        title: 'Eaches',
        dataIndex: 'orderTotalEaches',
        width: 100,
        blankWidth: 80,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      {
        title:'Large Hits',
        dataIndex: 'largeHits',
        width: 80,
        blankWidth: 50,
        // sorter: true,
        render: (value, record) => value === 'Y'?'Yes':value === 'N'?'No':null,
      },
      {
        title:'Under X Or Y',
        dataIndex: 'underXOrY',
        width: 80,
        blankWidth: 50,
        // sorter: true,
        render: (value, record) => value === 'Y'?'Yes':value === 'N'?'No':null,
      },
      {
        title:'Off Day',
        dataIndex: 'offDay',
        width: 80,
        blankWidth: 50,
        // sorter: true,
        render: (value, record) => value === 'Y'?'Yes':value === 'N'?'No':null,
      },
      {
        title: 'Order Source',
        dataIndex: 'orderSource',
        width: 150,
        blankWidth: 120,
        sorter: true,
        render: false,
      },
      {
        title: 'Delv Days',
        dataIndex: 'deliveryDays',
        width: 130,
        blankWidth: 110,
        sorter: true,
        render: false,
      },
    ],
    customerColumns: [
      {
        title: 'Date',
        dataIndex: 'dateStamp',
        width: null,
        blankWidth: 100,
        sorter: (itemOne, itemTwo) => sortString(itemOne.dateStamp, itemTwo.dateStamp),
      },
      {
        title: 'Time',
        dataIndex: 'timeStamp',
        width: null,
        blankWidth: 100,
        sorter: (itemOne, itemTwo) => sortString(itemOne.timeStamp, itemTwo.timeStamp),
      },
      {
        title: 'Log',
        dataIndex: 'event',
        width: 370,
        blankWidth: 350,
        sorter: (itemOne, itemTwo) => sortString(itemOne.event, itemTwo.event),
      },
      {
        title: 'User ID',
        dataIndex: 'userId',
        width: 95,
        blankWidth: 100,
        sorter: (itemOne, itemTwo) => sortString(itemOne.userId, itemTwo.userId),
      },
      {
        title: 'Event Source',
        dataIndex: 'source',
        width: 130,
        blankWidth: 120,
        sorter: (itemOne, itemTwo) => sortString(itemOne.source, itemTwo.source),
      },
    ],
  },
  {
    key: 'SC in Action',
    columns: [
      // {
      //   title: 'SC Name',
      //   dataIndex: 'scName',
      //   width: 170,
      //   blankWidth: 170,
      //   sorter: true,
      //   render: false,
      // },
      // {
      //   title: 'SC ID',
      //   dataIndex: 'scId',
      //   width: 118,
      //   blankWidth: 118,
      //   sorter: true,
      //   render: false,
      //   toggleModal: true,
      // },
      //add site column for SC in action
      {
        title: 'Site',
        dataIndex: 'site',
        width: 70,
        blankWidth: 70,
        sorter: true,
        render: false,
      },
      //Merge SC ID and SC Name and show as a single column "ID-Name" 
      {
          title: 'Name-ID',
          dataIndex: 'scId',
          width: 190,
          blankWidth: 180,
          sorter: true,
          render:false,
          toggleModal: true,
          className:'title-white-sapce'
        },
      {
        title: 'Sales',
        dataIndex: 'totalSales',
        width: 140,
        blankWidth: 140,
        sorter: true,
        className: 'tit-right',
        render: (value) => value === null || value === 0? '$0.00' : value && `$${value.toLocaleString('zh',{minimumFractionDigits:2})}`,
      },
      {
        title: 'Pieces',
        dataIndex: 'totalPiece',
        width: 100,
        blankWidth: 100,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      // {
      //   title: 'Cases',
      //   dataIndex: 'totalCases',
      //   width: 90,
      //   blankWidth: 90,
      //   sorter: true,
      //   render: false,
      //   className: 'tit-right'
      // },
      // {
      //   title: 'Eaches',
      //   dataIndex: 'totalEaches',
      //   width: 90,
      //   blankWidth: 90,
      //   sorter: true,
      //   render: false,
      //   className: 'tit-right'
      // },
      {
        title: '# of Cust',
        dataIndex: 'noOfCust',
        width: 90,
        blankWidth: 90,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      {
        title: 'Off Day Orders',
        dataIndex: 'offDayOrders',
        width: 120,
        blankWidth: 100,
        sorter: true,
        render: false,
        className: 'tit-right',
        toggleModal: true,
      },
      {
        title: 'Large Hits',
        dataIndex: 'largeHits',
        width: 120,
        blankWidth: 120,
        // sorter: true,
        render: false,
        className: 'tit-right',
        toggleModal: true,
      },
      {
        title: 'Order Under Min',
        dataIndex: 'underXOrY',
        width: 150,
        blankWidth: 150,
        // sorter: true,
        render: false,
        className: 'tit-right',
        toggleModal: true,
      },
      {
        title: '# of Orders',
        dataIndex: 'noOfOrders',
        width: 100,
        blankWidth: 100,
        sorter: true,
        render: (value) => value +'' +'\xa0\xa0\xa0\xa0',
        className: 'tit-right'
      },
    ],
    customerColumns: [
      {
        title: 'Cust #',
        dataIndex: 'customerNumber',
        width: 115,
        blankWidth: 115,
        sorter: true,
        render: false,
        toggleModal: true,
      },
      {
        title: 'Cust Name',
        dataIndex: 'customerName',
        width: 170,
        blankWidth: 110,
        sorter: true,
        render: false,
      },
      {
        title: '# Of Orders',
        dataIndex: 'noOfOrders',
        width: 120,
        blankWidth: 110,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      {
        title: 'Sales',
        dataIndex: 'totalSales',
        width: 140,
        blankWidth: 140,
        sorter: true,
        className: 'tit-right',
        render: (value) => value === null || value === 0? '$0.00' : value && `$${value.toLocaleString('zh',{minimumFractionDigits:2})}`,
      },
      {
        title: 'Cases',
        dataIndex: 'totalCases',
        width: 100,
        blankWidth: 100,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      {
        title: 'Eaches',
        dataIndex: 'totalEaches',
        width: 100,
        blankWidth: 100,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      {
        title: 'Off Day',
        dataIndex: 'offDay',
        width: 100,
        blankWidth: 100,
        sorter: true,
        render: false,
      },
      {
        title: 'Delv Days',
        dataIndex: 'deliveryDays',
        width: 130,
        blankWidth: 110,
        sorter: true,
        render: false,
      },
      {
        title: 'Acc Type',
        dataIndex: 'accountType',
        width: 100,
        blankWidth: 100,
        sorter: true,
        render: false,
      },
      {
        title: 'DSM',
        dataIndex: 'district',
        width: 180,
        blankWidth: 150,
        sorter: true,
        render: (value,record) =>{
          return renderMergedColumn(record.dsmName,record.district,'-');
         },
      },
      // {
      //   title: 'DSM Name',
      //   dataIndex: 'dsmName',
      //   width: 150,
      //   blankWidth: 110,
      //   sorter: true,
      //   render: false,
      // },
      {
        title: 'RSM',
        dataIndex: 'region',
        width: 180,
        blankWidth: 150,
        sorter: true,
        render: (value,record) =>{
          return renderMergedColumn(record.rsmName,record.region,'-');
         },
      },
      // {
      //   title: 'RSM Name',
      //   dataIndex: 'rsmName',
      //   width: 150,
      //   blankWidth: 110,
      //   sorter: true,
      //   render: false,
      // },
    ],
    orderColumns: [
      {
        title: 'Order #',
        dataIndex: 'orderNumber',
        width: 180,
        blankWidth: 100,
        sorter: true,
        render: false,
      },
      {
        title: 'Sales',
        dataIndex: 'orderTotalSales',
        width: 130,
        blankWidth: 130,
        sorter: true,
        className: 'tit-right',
        render: (value) => value === null || value === 0? '$0.00' : value && `$${value.toLocaleString('zh',{minimumFractionDigits:2})}`,
      },
      {
        title: 'Cases',
        dataIndex: 'orderTotalCases',
        width: 110,
        blankWidth: 110,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      {
        title: 'Eaches',
        dataIndex: 'orderTotalEaches',
        width: 110,
        blankWidth: 110,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      {
        title: 'Order Type',
        dataIndex: 'orderType',
        width: 120,
        blankWidth: 120,
        sorter: true,
        render: false,
      },
      {
        title: 'Off Day',
        dataIndex: 'offDay',
        width: 100,
        blankWidth: 100,
        sorter: true,
        render: false,
      },
      {
        title: 'Delv Days',
        dataIndex: 'deliveryDays',
        width: 130,
        blankWidth: 110,
        sorter: false,
        render: false,
      },
    ],
  },
];
export const CONST_REPORT_TABS_NEW_COLUMNS = [
  
  {
    key: 'Summary',
    accountType: {
      level: 0,
      columns: [
        {
          title: 'region',
          dataIndex: 'region',
          width: 160,
          blankWidth: 160,
          sorter: true,
          render: false,
          toggleModal: true,
        },
        {
          title: 'summary_totalSales',
          dataIndex: 'totalSales',
          width: 140,
          blankWidth: 140,
          sorter: true,
          render: value =>
            value === null || value === 0
              ? '$0.00'
              : value && `$${value.toLocaleString('zh', { minimumFractionDigits: 2 })}`,
          className: 'tit-right',
        },
        {
          title: 'summary_totalPiece',
          dataIndex: 'totalPiece',
          width: 100,
          blankWidth: 100,
          sorter: true,
          render: false,
          className: 'tit-right',
        },
        {
          title: 'summary_totalConfirmedPiece',
          dataIndex: 'totalConfirmedPiece',
          width: 125,
          blankWidth: 125,
          sorter: true,
          render: false,
          className: 'tit-right',
        },
        {
          title: 'averageSell',
          dataIndex: 'averageSell',
          width: 95,
          blankWidth: 95,
          sorter: false,
          render: value =>
            value === null || value === 0
              ? '$0.00'
              : value && `$${value.toLocaleString('zh', { minimumFractionDigits: 2 })}`,
          className: 'tit-right',
        },
        {
          title: 'salesPercentage',
          dataIndex: 'salesPercentage',
          width: 90,
          blankWidth: 90,
          sorter: false,
          render: value =>
            value === null || value === 0
              ? '0.00%'
              : value && `${value.toLocaleString('zh', { minimumFractionDigits: 2 })}%`,
          className: 'tit-right',
        },
        {
          title: 'summary_noOfOrders',
          dataIndex: 'noOfOrders',
          width: 110,
          blankWidth: 110,
          sorter: true,
          render: false,
          className: 'tit-right',
        },
        {
          title: 'summary_holdOrders',
          dataIndex: 'holdOrders',
          width: 115,
          blankWidth: 115,
          sorter: true,
          render: false,
          className: 'tit-right',
        },
        {
          title: 'offDaySales',
          dataIndex: 'offDaySales',
          width: 135,
          blankWidth: 135,
          sorter: true,
          render: (value) => value === null || value === 0? '$0.00' : value && `$${value.toLocaleString('zh',{minimumFractionDigits:2})}`,
          className: 'tit-right',
        },
        {
          title: 'offDayCases',
          dataIndex: 'offDayCases',
          width: 110,
          blankWidth: 110,
          sorter: true,
          render: false,
          className: 'tit-right',
        },
        {
          title: 'offDayEaches',
          dataIndex: 'offDayEaches',
          width: 110,
          blankWidth: 110,
          sorter: true,
          render: false,
          className: 'tit-right',
        },
        {
          title: 'summary_offDayOrders',
          dataIndex: 'offDayOrders',
          width: 110,
          blankWidth: 110,
          sorter: true,
          render: value => value + '' + '\xa0\xa0\xa0\xa0', //add some space for last column if last column is numberic, otherwise the value is very closer to vertical scrollbar
          className: 'tit-right',
        },
      ],
    },
    salesSite: {
      level: 1,
      columns: [
        {
          title: 'site',
          dataIndex: 'site',
          width: 70,
          blankWidth: 70,
          sorter: true,
          render: false,
          toggleModal: true,
        },
        // {
        //   title: 'accountType',
        //   dataIndex: 'accountType',
        //   width: 100,
        //   blankWidth: 100,
        //   sorter: true,
        //   render: false,
        //   toggleModal: true,
        // },
        {
          title: 'summary_totalSales',
          dataIndex: 'totalSales',
          width: 130,
          blankWidth: 130,
          sorter: true,
          render: value =>
            value === null || value === 0
              ? '$0.00'
              : value && `$${value.toLocaleString('zh', { minimumFractionDigits: 2 })}`,
          className: 'tit-right',
        },
        {
          title: 'summary_totalPiece',
          dataIndex: 'totalPiece',
          width: 90,
          blankWidth: 90,
          sorter: true,
          render: false,
          className: 'tit-right',
        },
        {
          title: 'summary_totalConfirmedPiece',
          dataIndex: 'totalConfirmedPiece',
          width: 125,
          blankWidth: 125,
          sorter: true,
          render: false,
          className: 'tit-right',
        },
        {
          title: 'averageSell',
          dataIndex: 'averageSell',
          width: 95,
          blankWidth: 95,
          sorter: false,
          render: value =>
            value === null || value === 0
              ? '$0.00'
              : value && `$${value.toLocaleString('zh', { minimumFractionDigits: 2 })}`,
          className: 'tit-right',
        },
        {
          title: 'salesPercentage',
          dataIndex: 'salesPercentage',
          width: 90,
          blankWidth: 90,
          sorter: false,
          render: value =>
            value === null || value === 0
              ? '0.00%'
              : value && `${value.toLocaleString('zh', { minimumFractionDigits: 2 })}%`,
          className: 'tit-right',
        },
        {
          title: 'summary_noOfOrders',
          dataIndex: 'noOfOrders',
          width: 110,
          blankWidth: 110,
          sorter: true,
          render: false,
          className: 'tit-right',
        },
        {
          title: 'summary_holdOrders',
          dataIndex: 'holdOrders',
          width: 110,
          blankWidth: 110,
          sorter: true,
          render: false,
          className: 'tit-right',
        },
        {
          title: 'offDaySales',
          dataIndex: 'offDaySales',
          width: 125,
          blankWidth: 125,
          sorter: true,
          render: (value) => value === null || value === 0? '$0.00' : value && `$${value.toLocaleString('zh',{minimumFractionDigits:2})}`,
          // toggleModal: true,
          className: 'tit-right',
        },
        {
          title: 'offDayCases',
          dataIndex: 'offDayCases',
          width: 110,
          blankWidth: 110,
          sorter: true,
          render: false,
          className: 'tit-right',
        },
        {
          title: 'offDayEaches',
          dataIndex: 'offDayEaches',
          width: 110,
          blankWidth: 110,
          sorter: true,
          render: false,
          className: 'tit-right',
        },
        {
          title: 'summary_offDayOrders',
          dataIndex: 'offDayOrders',
          width: 110,
          blankWidth: 110,
          sorter: true,
          render: value => value + '' + '\xa0\xa0\xa0\xa0', //add some space for last column if last column is numberic, otherwise the value is very closer to vertical scrollbar
          className: 'tit-right',
        },
      ],
    },
    summary: {
      level: 2,
      columns: [
        //add site column
        {
          title: 'site',
          dataIndex: 'site',
          width: 70,
          blankWidth: 70,
          sorter: true,
          render: false,
        },
        {
          title: 'accountType',
          dataIndex: 'accountType',
          width: 100,
          blankWidth: 100,
          sorter: true,
          render: false,
          toggleModal: true,
        },
        {
          title: 'summary_totalSales',
          dataIndex: 'totalSales',
          width: 130,
          blankWidth: 130,
          sorter: true,
          render: value =>
            value === null || value === 0
              ? '$0.00'
              : value && `$${value.toLocaleString('zh', { minimumFractionDigits: 2 })}`,
          className: 'tit-right',
        },
        {
          title: 'summary_totalPiece',
          dataIndex: 'totalPiece',
          width: 90,
          blankWidth: 90,
          sorter: true,
          render: false,
          className: 'tit-right',
        },
        {
          title: 'summary_totalConfirmedPiece',
          dataIndex: 'totalConfirmedPiece',
          width: 125,
          blankWidth: 125,
          sorter: true,
          render: false,
          className: 'tit-right',
        },
        // {
        //   title: 'Cases',
        //   dataIndex: 'totalCases',
        //   width: 90,
        //   blankWidth: 90,
        //   sorter: true,
        //   render: false,
        //   className: 'tit-right'
        // },
        // {
        //   title: 'Eaches',
        //   dataIndex: 'totalEaches',
        //   width: 90,
        //   blankWidth: 90,
        //   sorter: true,
        //   render: false,
        //   className: 'tit-right'
        // },
        {
          title: 'averageSell',
          dataIndex: 'averageSell',
          width: 95,
          blankWidth: 95,
          sorter: false,
          render: value =>
            value === null || value === 0
              ? '$0.00'
              : value && `$${value.toLocaleString('zh', { minimumFractionDigits: 2 })}`,
          className: 'tit-right',
        },
        {
          title: 'salesPercentage',
          dataIndex: 'salesPercentage',
          width: 90,
          blankWidth: 90,
          sorter: false,
          render: value =>
            value === null || value === 0
              ? '0.00%'
              : value && `${value.toLocaleString('zh', { minimumFractionDigits: 2 })}%`,
          className: 'tit-right',
        },
        {
          title: 'summary_noOfOrders',
          dataIndex: 'noOfOrders',
          width: 110,
          blankWidth: 110,
          sorter: true,
          render: false,
          className: 'tit-right',
        },
        {
          title: 'summary_holdOrders',
          dataIndex: 'holdOrders',
          width: 110,
          blankWidth: 110,
          sorter: true,
          render: false,
          className: 'tit-right',
        },
        {
          title: 'offDaySales',
          dataIndex: 'offDaySales',
          width: 125,
          blankWidth: 125,
          sorter: true,
          // render: (value) => value === null || value === 0? '' : value && `$${value.toLocaleString('zh',{minimumFractionDigits:2})}`,
          toggleModal: true,
          className: 'tit-right',
        },
        {
          title: 'offDayCases',
          dataIndex: 'offDayCases',
          width: 110,
          blankWidth: 110,
          sorter: true,
          render: false,
          className: 'tit-right',
        },
        {
          title: 'offDayEaches',
          dataIndex: 'offDayEaches',
          width: 110,
          blankWidth: 110,
          sorter: true,
          render: false,
          className: 'tit-right',
        },
        {
          title: 'summary_offDayOrders',
          dataIndex: 'offDayOrders',
          width: 110,
          blankWidth: 110,
          sorter: true,
          render: value => value + '' + '\xa0\xa0\xa0\xa0', //add some space for last column if last column is numberic, otherwise the value is very closer to vertical scrollbar
          className: 'tit-right',
        },
        // {
        //   title: 'GP Percentages',
        //   dataIndex: 'gpPercentages',
        //   width: 130,
        //   blankWidth: 130,
        //   sorter: false,
        //   render: (value) => value && `${value.toLocaleString()}%`,
        // },
      ],
    },
    customer: {
      level: 3,
      columns: [
        {
          title: 'customerName',
          dataIndex: 'customerName',
          width: 170,
          blankWidth: 110,
          sorter: true,
          render: false,
        },
        {
          title: 'customerNumber',
          dataIndex: 'customerNumber',
          width: 110,
          blankWidth: 110,
          sorter: true,
          render: false,
          toggleModal: true,
        },
        {
          title: 'summary_noOfOrders',
          dataIndex: 'noOfOrders',
          width: 110,
          blankWidth: 110,
          sorter: true,
          render: false,
          className: 'tit-right',
        },
        {
          title: 'summary_totalSales',
          dataIndex: 'totalSales',
          width: 130,
          blankWidth: 130,
          sorter: true,
          render: value =>
            value === null || value === 0
              ? '$0.00'
              : value && `$${value.toLocaleString('zh', { minimumFractionDigits: 2 })}`,
          className: 'tit-right',
        },
        {
          title: 'order_orderTotalCases',
          dataIndex: 'totalCases',
          width: 100,
          blankWidth: 100,
          sorter: true,
          render: false,
          className: 'tit-right',
        },
        {
          title: 'customer_totalEaches',
          dataIndex: 'totalEaches',
          width: 100,
          blankWidth: 100,
          sorter: true,
          render: false,
          className: 'tit-right',
        },
        {
          title: 'offDay',
          dataIndex: 'offDay',
          width: 90,
          blankWidth: 90,
          sorter: true,
          render: false,
        },
        {
          title: 'deliveryDays',
          dataIndex: 'deliveryDays',
          width: 130,
          blankWidth: 110,
          sorter: true,
          render: false,
        },
        {
          title: 'scId',
          dataIndex: 'scId',
          width: 180,
          blankWidth: 150,
          sorter: true,
          render: (value, record) => {
            return renderMergedColumn(record.scName, record.scId, '-');
          },
        },
        {
          title: 'district',
          dataIndex: 'district',
          width: 180,
          blankWidth: 150,
          sorter: true,
          render: (value, record) => {
            return renderMergedColumn(record.dsmName, record.district, '-');
          },
        },
        {
          title: 'summary_region',
          dataIndex: 'region',
          width: 180,
          blankWidth: 150,
          sorter: true,
          render: (value, record) => {
            return renderMergedColumn(record.rsmName, record.region, '-');
          },
        },
      ],
    },
    order: {
      level: 4,
      columns: [
        {
          title: 'orderNumber',
          dataIndex: 'orderNumber',
          width: 180,
          blankWidth: 100,
          sorter: true,
          render: false,
          toggleModal: true,
        },
        {
          title: 'summary_totalSales',
          dataIndex: 'orderTotalSales',
          width: 120,
          blankWidth: 120,
          sorter: true,
          className: 'tit-right',
          render: value =>
            value === null || value === 0
              ? '$0.00'
              : value && `$${value.toLocaleString('zh', { minimumFractionDigits: 2 })}`,
        },
        {
          title: 'order_orderTotalCases',
          dataIndex: 'orderTotalCases',
          width: 110,
          blankWidth: 110,
          sorter: true,
          render: false,
          className: 'tit-right',
        },
        {
          title: 'customer_totalEaches',
          dataIndex: 'orderTotalEaches',
          width: 110,
          blankWidth: 110,
          sorter: true,
          render: false,
          className: 'tit-right',
        },
        {
          title: 'orderType',
          dataIndex: 'orderType',
          width: 120,
          blankWidth: 120,
          sorter: true,
          render: false,
        },
        {
          title: 'offDay',
          dataIndex: 'offDay',
          width: 100,
          blankWidth: 100,
          sorter: true,
          render: false,
        },
        {
          title: 'deliveryDays',
          dataIndex: 'deliveryDays',
          width: 130,
          blankWidth: 110,
          sorter: false,
          render: false,
        },
      ],
    },
    orderDetail: {
      level: 5,
      columns: [{
        title: 'itemNumber',
        dataIndex: 'itemNumber',
        width: 180,
        blankWidth: 180,
        sorter: true,
        render: false,
      },{
        title: 'itemDescription',
        dataIndex: 'itemDescription',
        width: 180,
        blankWidth: 180,
        sorter: true,
        render: false,
      },{
        title: 'order_quantity',
        dataIndex: 'quantity',
        width: 150,
        blankWidth: 150,
        sorter: true,
        render: false,
      },{
        title: 'order_quantityAllocAgainstOnHand',
        dataIndex: 'quantityAllocAgainstOnHand',
        width: 150,
        blankWidth: 150,
        sorter: true,
        render: false,
      },{
        title: 'splitCode',
        dataIndex: 'splitCode',
        width: 150,
        blankWidth: 150,
        sorter: true,
        render: false,
      },{
        title: 'originalItemUnitPrice',
        dataIndex: 'originalItemUnitPrice',
        width: 180,
        blankWidth: 180,
        sorter: true,
        render: value =>
            value === null || value === 0
              ? '$0.00'
              : value && `$${value.toLocaleString('zh', { minimumFractionDigits: 2 })}`,
      },{
        title: 'order_orderDetailExtendedPrice',
        dataIndex: 'orderDetailExtendedPrice',
        width: 180,
        blankWidth: 180,
        sorter: true,
        render: value =>
            value === null || value === 0
              ? '$0.00'
              : value && `$${value.toLocaleString('zh', { minimumFractionDigits: 2 })}`,
      }],
    },
  },
  {
    key: 'Orders',
    summary:{
      level: 1,
      columns:[
        {
          title: 'orderChannelType',
          dataIndex: 'orderChannelType',
          width: 180,
          blankWidth: 180,
          sorter: true,
          render: false,
          toggleModal: true,
        },
        {
          title: 'order_noOfCust',
          dataIndex: 'noOfCust',
          width: 135,
          blankWidth: 135,
          sorter: true,
          render: false,
          className: 'tit-right'
        },
        {
          title: 'order_noOfOrders',
          dataIndex: 'noOfOrders',
          width: 135,
          blankWidth: 135,
          sorter: true,
          render: false,
          className: 'tit-right'
        },
        {
          title: 'order_offDayOrders',
          dataIndex: 'offDayOrders',
          width: 135,
          blankWidth: 135,
          sorter: true,
          render: false,
          className: 'tit-right'
        },
        {
          title: 'summary_totalSales',
          dataIndex: 'totalSales',
          width: 150,
          blankWidth: 150,
          sorter: true,
          render: (value) => value === null || value === 0? '$0.00' : value && `$${value.toLocaleString('zh',{minimumFractionDigits:2})}`,
          className: 'tit-right'
        },
        {
          title: 'order_orderTotalCases',
          dataIndex: 'totalCases',
          width: 110,
          blankWidth: 110,
          sorter: true,
          render: false,
          className: 'tit-right'
        },
        {
          title: 'customer_totalEaches',
          dataIndex: 'totalEaches',
          width: 110,
          blankWidth: 110,
          sorter: true,
          render: (value) => value +'' +'\xa0\xa0\xa0\xa0',
          className: 'tit-right'
        },
      ],
    },
    customer:{
      level:2,
      columns:[
        //add site column
        {
          title: 'site',
          dataIndex: 'site',
          width: 70,
          blankWidth: 70,
          sorter: true,
          render: false,
        },
        {
          title: 'accountType',
          dataIndex: 'accountType',
          width: 100,
          blankWidth: 100,
          sorter: true,
          render: false,
          toggleModal: true,
        },
        {
          title: 'summary_totalSales',
          dataIndex: 'totalSales',
          width: 130,
          blankWidth: 130,
          sorter: true,
          render: (value) => value === null || value === 0? '$0.00' : value && `$${value.toLocaleString('zh',{minimumFractionDigits:2})}`,
          className: 'tit-right'
        },
        {
          title: 'summary_totalPiece',
          dataIndex: 'totalPiece',
          width: 100,
          blankWidth: 100,
          sorter: true,
          render: false,
          className: 'tit-right'
        },
        // {
        //   title: 'Cases',
        //   dataIndex: 'totalCases',
        //   width: 90,
        //   blankWidth: 90,
        //   sorter: true,
        //   render: false,
        //   className: 'tit-right'
        // },
        // {
        //   title: 'Eaches',
        //   dataIndex: 'totalEaches',
        //   width: 90,
        //   blankWidth: 90,
        //   sorter: true,
        //   render: false,
        //   className: 'tit-right'
        // },
        {
          title: 'averageSell',
          dataIndex: 'averageSell',
          width: 95,
          blankWidth: 95,
          sorter: false,
          render: (value) => value === null || value === 0? '$0.00' : value && `$${value.toLocaleString('zh',{minimumFractionDigits:2})}`,
          className: 'tit-right'
        },
        {
          title: 'salesPercentage',
          dataIndex: 'salesPercentage',
          width: 90,
          blankWidth: 90,
          sorter: false,
          render: (value) => value === null || value === 0? '0.00%' : value && `${value.toLocaleString('zh',{minimumFractionDigits:2})}%`,
          className: 'tit-right'
        },
        {
          title: 'order_noOfOrders',
          dataIndex: 'noOfOrders',
          width: 110,
          blankWidth: 110,
          sorter: true,
          render: false,
          className: 'tit-right'
        },
        {
          title: 'summary_holdOrders',
          dataIndex: 'holdOrders',
          width: 110,
          blankWidth: 110,
          sorter: true,
          render: false,
          className: 'tit-right'
        },
        {
          title: 'offDaySales',
          dataIndex: 'offDaySales',
          width: 125,
          blankWidth: 125,
          sorter: true,
          // render: (value) => value === null || value === 0? '0.00' : value && `$${value.toLocaleString('zh',{minimumFractionDigits:2})}`,
          toggleModal: true,
          className: 'tit-right'
        },
        {
          title: 'offDayCases',
          dataIndex: 'offDayCases',
          width: 110,
          blankWidth: 110,
          sorter: true,
          render: false,
          className: 'tit-right'
        },
        {
          title: 'offDayEaches',
          dataIndex: 'offDayEaches',
          width: 110,
          blankWidth: 110,
          sorter: true,
          render: false,
          className: 'tit-right'
        },
        {
          title: 'customer_offDayOrders',
          dataIndex: 'offDayOrders',
          width: 110,
          blankWidth: 110,
          sorter: true,
          render: (value) => value +'' +'\xa0\xa0\xa0\xa0',
          className: 'tit-right'
        },
        // {
        //   title: 'GP Percentages',
        //   dataIndex: 'gpPercentages',
        //   width: 130,
        //   blankWidth: 140,
        //   sorter: false,
        //   render: (value) => value && `${value.toLocaleString()}%`,
        // },
      ],
    },
    order:{
      level:3,
      columns:
      [
        {
          title: 'orderNumber',
          dataIndex: 'orderNumber',
          width: 180,
          blankWidth: 100,
          sorter: true,
          render: false,
          toggleModal: true,
        },
        {
          title: 'siteNumber',
          dataIndex: 'site',
          width: 70,
          blankWidth: 70,
          sorter: true,
          render: false,
        },
        {
          title: 'customerNumber',
          dataIndex: 'customerNumber',
          width: 100,
          blankWidth: 100,
          sorter: true,
          render: false,
        },
        {
          title: 'customerName',
          dataIndex: 'customerName',
          width: 170,
          blankWidth: 110,
          sorter: true,
          render: false,
        },
        {
          title: 'summary_totalSales',
          dataIndex: 'orderTotalSales',
          width: 130,
          blankWidth: 130,
          sorter: true,
          className: 'tit-right',
          render: (value) => value === null || value === 0? '$0.00' : value && `$${value.toLocaleString('zh',{minimumFractionDigits:2})}`,
        },      
        {
          title: 'order_orderTotalCases',
          dataIndex: 'orderTotalCases',
          width: 90,
          blankWidth: 80,
          sorter: true,
          render: false,
          className: 'tit-right'
        },
        {
          title: 'customer_totalEaches',
          dataIndex: 'orderTotalEaches',
          width: 90,
          blankWidth: 80,
          sorter: true,
          render: false,
          className: 'tit-right'
        },
        {
          title:'largeHits',
          dataIndex: 'largeHits',
          width: 80,
          blankWidth: 50,
          // sorter: true,
          render: (value, record) => value === 'Y'?'Yes':value === 'N'?'No':null,
        },
        {
          title:'underXOrY',
          dataIndex: 'underXOrY',
          width: 100,
          blankWidth: 50,
          // sorter: true,
          render: (value, record) => value === 'Y'?'Yes':value === 'N'?'No':null,
        },
        {
          title:'offDay',
          dataIndex: 'offDay',
          width: 80,
          blankWidth: 50,
          // sorter: true,
          render: (value, record) => value === 'Y'?'Yes':value === 'N'?'No':null,
        },
        {
          title: 'orderSource',
          dataIndex: 'orderSource',
          width: 150,
          blankWidth: 120,
          sorter: true,
          render: false,
        },
        {
          title: 'deliveryDays',
          dataIndex: 'deliveryDays',
          width: 130,
          blankWidth: 110,
          sorter: true,
          render: false,
        },
      ],
    },
    orderDetail: {
      level: 4,
      columns: [{
        title: 'itemNumber',
        dataIndex: 'itemNumber',
        width: 180,
        blankWidth: 180,
        sorter: true,
        render: false,
      },{
        title: 'itemDescription',
        dataIndex: 'itemDescription',
        width: 180,
        blankWidth: 180,
        sorter: true,
        render: false,
      },{
        title: 'order_quantity',
        dataIndex: 'quantity',
        width: 150,
        blankWidth: 150,
        sorter: true,
        render: false,
      },{
        title: 'order_quantityAllocAgainstOnHand',
        dataIndex: 'quantityAllocAgainstOnHand',
        width: 150,
        blankWidth: 150,
        sorter: true,
        render: false,
      },{
        title: 'splitCode',
        dataIndex: 'splitCode',
        width: 150,
        blankWidth: 150,
        sorter: true,
        render: false,
      },{
        title: 'originalItemUnitPrice',
        dataIndex: 'originalItemUnitPrice',
        width: 180,
        blankWidth: 180,
        sorter: true,
        render: value =>
            value === null || value === 0
              ? '$0.00'
              : value && `$${value.toLocaleString('zh', { minimumFractionDigits: 2 })}`,
      },{
        title: 'order_orderDetailExtendedPrice',
        dataIndex: 'orderDetailExtendedPrice',
        width: 180,
        blankWidth: 180,
        sorter: true,
        render: value =>
            value === null || value === 0
              ? '$0.00'
              : value && `$${value.toLocaleString('zh', { minimumFractionDigits: 2 })}`,
      }],
    },
  },
  {
    key: 'Future Orders',
    summary:{
      level: 1,
      columns:[
        {
          title: 'orderChannelType',
          dataIndex: 'orderChannelType',
          width: 180,
          blankWidth: 180,
          sorter: true,
          render: false,
          toggleModal: true,
        },
        {
          title: 'order_noOfCust',
          dataIndex: 'noOfCust',
          width: 135,
          blankWidth: 135,
          sorter: true,
          render: false,
          className: 'tit-right'
        },
        {
          title: 'order_noOfOrders',
          dataIndex: 'noOfOrders',
          width: 110,
          blankWidth: 110,
          sorter: true,
          render: false,
          className: 'tit-right'
        },
        {
          title: 'order_offDayOrders',
          dataIndex: 'offDayOrders',
          width: 135,
          blankWidth: 135,
          sorter: true,
          render: false,
          className: 'tit-right'
        },
        {
          title: 'summary_totalSales',
          dataIndex: 'totalSales',
          width: 150,
          blankWidth: 150,
          sorter: true,
          className: 'tit-right',
          render: (value) => value === null || value === 0? '$0.00' : value && `$${value.toLocaleString('zh',{minimumFractionDigits:2})}`,
        },
        {
          title: 'order_orderTotalCases',
          dataIndex: 'totalCases',
          width: 110,
          blankWidth: 110,
          sorter: true,
          render: false,
          className: 'tit-right'
        },
        {
          title: 'customer_totalEaches',
          dataIndex: 'totalEaches',
          width: 110,
          blankWidth: 110,
          sorter: true,
          render: (value) => value +'' +'\xa0\xa0\xa0\xa0',
          className: 'tit-right'
        },
      ],
    },
    customer:{
      level: 2,
      columns:[
        //add site column
        {
          title: 'site',
          dataIndex: 'site',
          width: 70,
          blankWidth: 70,
          sorter: true,
          render: false,
        },
        {
          title: 'accountType',
          dataIndex: 'accountType',
          width: 100,
          blankWidth: 100,
          sorter: true,
          render: false,
          toggleModal: true,
        },
        {
          title: 'summary_totalSales',
          dataIndex: 'totalSales',
          width: 130,
          blankWidth: 130,
          sorter: true,
          className: 'tit-right',
          render: (value) => value === null || value === 0? '$0.00' : value && `$${value.toLocaleString('zh',{minimumFractionDigits:2})}`,
        },
        {
          title: 'order_orderTotalCases',
          dataIndex: 'totalCases',
          width: 90,
          blankWidth: 90,
          sorter: true,
          render: false,
          className: 'tit-right'
        },
        {
          title: 'customer_totalEaches',
          dataIndex: 'totalEaches',
          width: 90,
          blankWidth: 90,
          sorter: true,
          render: false,
          className: 'tit-right'
        },
        {
          title: 'averageSell',
          dataIndex: 'averageSell',
          width: 100,
          blankWidth: 100,
          sorter: false,
          className: 'tit-right',
          render: (value) => value === null || value === 0? '$0.00' : value && `$${value.toLocaleString('zh',{minimumFractionDigits:2})}`,
        },
        {
          title: 'salesPercentage',
          dataIndex: 'salesPercentage',
          width: 90,
          blankWidth: 90,
          sorter: false,
          className: 'tit-right',
          render: (value) => value === null || value === 0? '0.00%' : value && `${value.toLocaleString('zh',{minimumFractionDigits:2})}%`,
        },
        {
          title: 'order_noOfOrders',
          dataIndex: 'noOfOrders',
          width: 110,
          blankWidth: 110,
          sorter: true,
          render: false,
          className: 'tit-right'
        },
        {
          title: 'customer_holdOrders',
          dataIndex: 'holdOrders',
          width: 140,
          blankWidth: 140,
          sorter: true,
          render: false,
          className: 'tit-right'
        },
        {
          title: 'offDaySales',
          dataIndex: 'offDaySales',
          width: 125,
          blankWidth: 125,
          sorter: true,
          toggleModal: true,
          className: 'tit-right'
        },
        {
          title: 'offDayCases',
          dataIndex: 'offDayCases',
          width: 125,
          blankWidth: 125,
          sorter: true,
          render: false,
          className: 'tit-right'
        },
        {
          title: 'offDayEaches',
          dataIndex: 'offDayEaches',
          width: 130,
          blankWidth: 130,
          sorter: true,
          render: false,
          className: 'tit-right'
        },
        {
          title: 'summary_offDayOrders',
          dataIndex: 'offDayOrders',
          width: 125,
          blankWidth: 125,
          sorter: true,
          render: (value) => value +'' +'\xa0\xa0\xa0\xa0',
          className: 'tit-right'
        },
      ],
    },
    order:{
      level: 3,
      columns:[{
        title: 'orderNumber',
        dataIndex: 'orderNumber',
        width: 180,
        blankWidth: 100,
        sorter: true,
        render: false,
        toggleModal: true,
      },
      {
        title: 'siteNumber',
        dataIndex: 'site',
        width: 80,
        blankWidth: 80,
        sorter: true,
        render: false,
      },
      {
        title: 'customerNumber',
        dataIndex: 'customerNumber',
        width: 100,
        blankWidth: 100,
        sorter: true,
        render: false,
      },
      {
        title: 'customerName',
        dataIndex: 'customerName',
        width: 170,
        blankWidth: 110,
        sorter: true,
        render: false,
      },
      {
        title: 'summary_totalSales',
        dataIndex: 'orderTotalSales',
        width: 130,
        blankWidth: 130,
        sorter: true,
        className: 'tit-right',
        render: (value) => value === null || value === 0? '$0.00' : value && `$${value.toLocaleString('zh',{minimumFractionDigits:2})}`,
      },      
      {
        title: 'order_orderTotalCases',
        dataIndex: 'orderTotalCases',
        width: 100,
        blankWidth: 80,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      {
        title: 'customer_totalEaches',
        dataIndex: 'orderTotalEaches',
        width: 100,
        blankWidth: 80,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      {
        title:'largeHits',
        dataIndex: 'largeHits',
        width: 80,
        blankWidth: 50,
        // sorter: true,
        render: (value, record) => value === 'Y'?'Yes':value === 'N'?'No':null,
      },
      {
        title:'underXOrY',
        dataIndex: 'underXOrY',
        width: 100,
        blankWidth: 50,
        // sorter: true,
        render: (value, record) => value === 'Y'?'Yes':value === 'N'?'No':null,
      },
      {
        title:'offDay',
        dataIndex: 'offDay',
        width: 80,
        blankWidth: 50,
        // sorter: true,
        render: (value, record) => value === 'Y'?'Yes':value === 'N'?'No':null,
      },
      {
        title: 'orderSource',
        dataIndex: 'orderSource',
        width: 150,
        blankWidth: 120,
        sorter: true,
        render: false,
      },
      {
        title: 'deliveryDays',
        dataIndex: 'deliveryDays',
        width: 130,
        blankWidth: 110,
        sorter: true,
        render: false,
      }]
    },
    orderDetail: {
      level: 4,
      columns: [{
        title: 'itemNumber',
        dataIndex: 'itemNumber',
        width: 180,
        blankWidth: 180,
        sorter: true,
        render: false,
      },{
        title: 'itemDescription',
        dataIndex: 'itemDescription',
        width: 180,
        blankWidth: 180,
        sorter: true,
        render: false,
      },{
        title: 'order_quantity',
        dataIndex: 'quantity',
        width: 150,
        blankWidth: 150,
        sorter: true,
        render: false,
      },{
        title: 'order_quantityAllocAgainstOnHand',
        dataIndex: 'quantityAllocAgainstOnHand',
        width: 150,
        blankWidth: 150,
        sorter: true,
        render: false,
      },{
        title: 'splitCode',
        dataIndex: 'splitCode',
        width: 150,
        blankWidth: 150,
        sorter: true,
        render: false,
      },{
        title: 'originalItemUnitPrice',
        dataIndex: 'originalItemUnitPrice',
        width: 180,
        blankWidth: 180,
        sorter: true,
        render: value =>
            value === null || value === 0
              ? '$0.00'
              : value && `$${value.toLocaleString('zh', { minimumFractionDigits: 2 })}`,
      },{
        title: 'order_orderDetailExtendedPrice',
        dataIndex: 'orderDetailExtendedPrice',
        width: 180,
        blankWidth: 180,
        sorter: true,
        render: value =>
            value === null || value === 0
              ? '$0.00'
              : value && `$${value.toLocaleString('zh', { minimumFractionDigits: 2 })}`,
      }],
    },
  },
  {
    key: 'SC in Action',
    summary:{
      level: 1,
      columns:[{
        title: 'site',
        dataIndex: 'site',
        width: 70,
        blankWidth: 70,
        sorter: true,
        render: false,
      },
      //Merge SC ID and SC Name and show as a single column "ID-Name" 
      {
          title: 'scId',
          dataIndex: 'scId',
          width: 190,
          blankWidth: 180,
          sorter: true,
          render:false,
          toggleModal: true,
          className:'title-white-sapce'
        },
      {
        title: 'summary_totalSales',
        dataIndex: 'totalSales',
        width: 140,
        blankWidth: 140,
        sorter: true,
        className: 'tit-right',
        render: (value) => value === null || value === 0? '$0.00' : value && `$${value.toLocaleString('zh',{minimumFractionDigits:2})}`,
      },
      {
        title: 'summary_totalPiece',
        dataIndex: 'totalPiece',
        width: 100,
        blankWidth: 100,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      {
        title: 'order_noOfCust',
        dataIndex: 'noOfCust',
        width: 90,
        blankWidth: 90,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      {
        title: 'summary_offDayOrders',
        dataIndex: 'offDayOrders',
        width: 120,
        blankWidth: 100,
        sorter: true,
        render: false,
        className: 'tit-right',
        toggleModal: true,
      },
      {
        title: 'largeHits',
        dataIndex: 'largeHits',
        width: 120,
        blankWidth: 120,
        // sorter: true,
        render: false,
        className: 'tit-right',
        toggleModal: true,
      },
      {
        title: 'sc_underXOrY',
        dataIndex: 'underXOrY',
        width: 150,
        blankWidth: 150,
        // sorter: true,
        render: false,
        className: 'tit-right',
        toggleModal: true,
      },
      {
        title: 'order_noOfOrders',
        dataIndex: 'noOfOrders',
        width: 100,
        blankWidth: 100,
        sorter: true,
        render: (value) => value +'' +'\xa0\xa0\xa0\xa0',
        className: 'tit-right'
      },]
    },
    customer:{
      level:2,
      columns:[{
        title: 'customerNumber',
        dataIndex: 'customerNumber',
        width: 115,
        blankWidth: 115,
        sorter: true,
        render: false,
        toggleModal: true,
      },
      {
        title: 'customerName',
        dataIndex: 'customerName',
        width: 170,
        blankWidth: 110,
        sorter: true,
        render: false,
      },
      {
        title: 'order_noOfOrders',
        dataIndex: 'noOfOrders',
        width: 120,
        blankWidth: 110,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      {
        title: 'summary_totalSales',
        dataIndex: 'totalSales',
        width: 140,
        blankWidth: 140,
        sorter: true,
        className: 'tit-right',
        render: (value) => value === null || value === 0? '$0.00' : value && `$${value.toLocaleString('zh',{minimumFractionDigits:2})}`,
      },
      {
        title: 'order_orderTotalCases',
        dataIndex: 'totalCases',
        width: 100,
        blankWidth: 100,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      {
        title: 'customer_totalEaches',
        dataIndex: 'totalEaches',
        width: 100,
        blankWidth: 100,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      {
        title: 'offDay',
        dataIndex: 'offDay',
        width: 100,
        blankWidth: 100,
        sorter: true,
        render: false,
      },
      {
        title: 'deliveryDays',
        dataIndex: 'deliveryDays',
        width: 130,
        blankWidth: 110,
        sorter: true,
        render: false,
      },
      {
        title: 'accountType',
        dataIndex: 'accountType',
        width: 100,
        blankWidth: 100,
        sorter: true,
        render: false,
      },
      {
        title: 'district',
        dataIndex: 'district',
        width: 180,
        blankWidth: 150,
        sorter: true,
        render: (value,record) =>{
          return renderMergedColumn(record.dsmName,record.district,'-');
         },
      },
      {
        title: 'summary_region',
        dataIndex: 'region',
        width: 180,
        blankWidth: 150,
        sorter: true,
        render: (value,record) =>{
          return renderMergedColumn(record.rsmName,record.region,'-');
         },
      }]
    },
    order:{
      level:3,
      columns:[{
        title: 'orderNumber',
        dataIndex: 'orderNumber',
        width: 180,
        blankWidth: 100,
        sorter: true,
        render: false,
        toggleModal: true,
      },
      {
        title: 'summary_totalSales',
        dataIndex: 'orderTotalSales',
        width: 130,
        blankWidth: 130,
        sorter: true,
        className: 'tit-right',
        render: (value) => value === null || value === 0? '$0.00' : value && `$${value.toLocaleString('zh',{minimumFractionDigits:2})}`,
      },
      {
        title: 'order_orderTotalCases',
        dataIndex: 'orderTotalCases',
        width: 110,
        blankWidth: 110,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      {
        title: 'customer_totalEaches',
        dataIndex: 'orderTotalEaches',
        width: 110,
        blankWidth: 110,
        sorter: true,
        render: false,
        className: 'tit-right'
      },
      {
        title: 'orderType',
        dataIndex: 'orderType',
        width: 120,
        blankWidth: 120,
        sorter: true,
        render: false,
      },
      {
        title: 'offDay',
        dataIndex: 'offDay',
        width: 100,
        blankWidth: 100,
        sorter: true,
        render: false,
      },
      {
        title: 'deliveryDays',
        dataIndex: 'deliveryDays',
        width: 130,
        blankWidth: 110,
        sorter: false,
        render: false,
      }]
    },
    orderDetail: {
      level: 4,
      columns: [{
        title: 'itemNumber',
        dataIndex: 'itemNumber',
        width: 180,
        blankWidth: 180,
        sorter: true,
        render: false,
      },{
        title: 'itemDescription',
        dataIndex: 'itemDescription',
        width: 180,
        blankWidth: 180,
        sorter: true,
        render: false,
      },{
        title: 'order_quantity',
        dataIndex: 'quantity',
        width: 150,
        blankWidth: 150,
        sorter: true,
        render: false,
      },{
        title: 'order_quantityAllocAgainstOnHand',
        dataIndex: 'quantityAllocAgainstOnHand',
        width: 150,
        blankWidth: 150,
        sorter: true,
        render: false,
      },{
        title: 'splitCode',
        dataIndex: 'splitCode',
        width: 150,
        blankWidth: 150,
        sorter: true,
        render: false,
      },{
        title: 'originalItemUnitPrice',
        dataIndex: 'originalItemUnitPrice',
        width: 180,
        blankWidth: 180,
        sorter: true,
        render: value =>
            value === null || value === 0
              ? '$0.00'
              : value && `$${value.toLocaleString('zh', { minimumFractionDigits: 2 })}`,
      },{
        title: 'order_orderDetailExtendedPrice',
        dataIndex: 'orderDetailExtendedPrice',
        width: 180,
        blankWidth: 180,
        sorter: true,
        render: value =>
        value === null || value === 0
          ? '$0.00'
          : value && `$${value.toLocaleString('zh', { minimumFractionDigits: 2 })}`,
      }],
    },
  },
  {
    key: 'OOS Item',
    summary:{
      level: 1,
      columns:[{
        title: 'site',
        dataIndex: 'site',
        width: 70,
        blankWidth: 70,
        sorter: true,
        render: false,
      },
      {
        title: 'category',
        dataIndex: 'category',
        width: 140,
        blankWidth: 140,
        sorter: true,
        render: false,
        toggleModal: true,
      },
      {
        title: 'OOS_OOS',
        dataIndex: 'oos',
        width: 100,
        blankWidth: 100,
        render: false,
        className: 'tit-right'
      },
      {
        title: 'OOS_lostSales',
        dataIndex: 'lostSales',
        width: 110,
        blankWidth: 110,
        render: (value) => value === null || value === 0? '$0.00' : value && `$${value.toLocaleString('zh',{minimumFractionDigits:2})}`,
        className: 'tit-right'
      },
      {
        title: 'preShipDirSubs',
        dataIndex: 'preShipDirSubs',
        width: 120,
        blankWidth: 120,
        render: (value) => value === null || value === 0? '0.00%' : value && `${value.toLocaleString('zh',{minimumFractionDigits:2})}%`,
        className: 'tit-right',
      },
      {
        title: 'preShipAllSubs',
        dataIndex: 'preShipAllSubs',
        width: 120,
        blankWidth: 120,
        render: (value) => value === null || value === 0? '0.00%' : value && `${value.toLocaleString('zh',{minimumFractionDigits:2})}%`,
        className: 'last-col-padding',
      }
     ]
    },
    order:{
      level:2,
      columns:[{
        title: 'site',
        dataIndex: 'site',
        width: 70,
        blankWidth: 70,
        sorter: true,
        render: false,
      },
      {
        title: 'itemNumber',
        dataIndex: 'itemNumber',
        width: 100,
        blankWidth: 100,
        sorter: true,
        render: false,
      },
      {
        title: 'itemDesc',
        dataIndex: 'itemDescription',
        width: 120,
        blankWidth: 120,
        sorter: true,
        render: false,
      },
      {
        title: 'brand',
        dataIndex: 'originalItemBrand',
        width: 110,
        blankWidth: 110,
        sorter: true,
        render: false,
      },
      {
        title: 'totalOrders',
        dataIndex: 'totalOrders',
        width: 120,
        blankWidth: 120,
        sorter: true,
        render: false,
        toggleModal: true,
      },
      {
        title: 'OOS_OOS',
        dataIndex: 'oos',
        width: 100,
        blankWidth: 100,
        render: false,
      },
      {
        title: 'OOS_lostSales',
        dataIndex: 'lostSales',
        width: 130,
        blankWidth: 110,
        render: (value) => value === null || value === 0? '$0.00' : value && `$${value.toLocaleString('zh',{minimumFractionDigits:2})}`,
      },
      {
        title: 'vendorNumber',
        dataIndex: 'vendorNumber',
        width: 130,
        blankWidth: 110,
        sorter: true,
        render: false,
      },
      {
        title: 'vendorName',
        dataIndex: 'vendorName',
        width: 130,
        blankWidth: 110,
        sorter: true,
        render: false,
      },
      // {
      //   title: 'vendorPh',
      //   dataIndex: 'vendorPh',
      //   width: 130,
      //   blankWidth: 110,
      //   sorter: true,
      //   render: false,
      // }
    ]
    },
    customer:{
      level:3,
      columns:[{
        title: 'site',
        dataIndex: 'site',
        width: 70,
        blankWidth: 70,
        sorter: true,
        render: false,
      },
      {
        title: 'Cust #',
        dataIndex: 'customerShipTo',
        width: 120,
        blankWidth: 110,
        sorter: true,
        render: false,
      },
      {
        title: 'name',
        dataIndex: 'customerName',
        width: 170,
        blankWidth: 110,
        sorter: true,
        render: false,
      },
      {
        title: 'orderNumber',
        dataIndex: 'orderNumber',
        width: 180,
        blankWidth: 100,
        sorter: true,
        render: false,
      },
      {
        title: 'OOS_OOS',
        dataIndex: 'oos',
        width: 100,
        blankWidth: 100,
        render: false,
        className: 'tit-right',
      },
      {
        title: 'OOS_lostSales',
        dataIndex: 'lostSales',
        width: 110,
        blankWidth: 110,
        render: (value) => value === null || value === 0? '$0.00' : value && `$${value.toLocaleString('zh',{minimumFractionDigits:2})}`,
        className: 'tit-right'
      },
      {
        title: 'preShipDirSubs',
        dataIndex: 'preShipDirSubs',
        width: 120,
        blankWidth: 120,
        render: (value) => value === null || value === 0? '0.00%' : value && `${value.toLocaleString('zh',{minimumFractionDigits:2})}%`,
        className: 'tit-right',
      },
      {
        title: 'preShipAllSubs',
        dataIndex: 'preShipAllSubs',
        width: 120,
        blankWidth: 120,
        render: (value) => value === null || value === 0? '0.00%' : value && `${value.toLocaleString('zh',{minimumFractionDigits:2})}%`,
        className: 'last-col-padding',
      }]
    }
  },
  {
    key: 'OOS Account',
    summary: {
      level: 1,
      columns: [{
        title: 'site',
        dataIndex: 'site',
        width: 70,
        blankWidth: 70,
        sorter: true,
        render: false,
      },
      {
        title: 'dashboard_accountType',
        dataIndex: 'accountType',
        width: 140,
        blankWidth: 140,
        sorter: true,
        render: false,
        toggleModal: true,
      },
      {
        title: 'OOS_OOS',
        dataIndex: 'oos',
        width: 100,
        blankWidth: 100,
        render: false,
        className: 'tit-right'
      },
      {
        title: 'OOS_lostSales',
        dataIndex: 'lostSales',
        width: 110,
        blankWidth: 110,
        render: (value) => value === null || value === 0 ? '$0.00' : value && `$${value.toLocaleString('zh', { minimumFractionDigits: 2 })}`,
        className: 'tit-right'
      },
      {
        title: 'preShipDirSubs',
        dataIndex: 'preShipDirSubs',
        width: 120,
        blankWidth: 120,
        render: (value) => value === null || value === 0 ? '0.00%' : value && `${value.toLocaleString('zh', { minimumFractionDigits: 2 })}%`,
        className: 'tit-right',
      },
      {
        title: 'preShipAllSubs',
        dataIndex: 'preShipAllSubs',
        width: 120,
        blankWidth: 120,
        render: (value) => value === null || value === 0 ? '0.00%' : value && `${value.toLocaleString('zh', { minimumFractionDigits: 2 })}%`,
        className: 'last-col-padding',
      }
      ]
    },
    customer: {
      level: 2,
      columns: [{
        title: 'site',
        dataIndex: 'site',
        width: 70,
        blankWidth: 70,
        sorter: true,
        render: false,
      },
      {
        title: 'customer#',
        dataIndex: 'customerShipTo',
        width: 140,
        blankWidth: 140,
        sorter: true,
        render: false,
        toggleModal: true,
      },
      {
        title: 'name',
        dataIndex: 'customerName',
        width: 140,
        blankWidth: 140,
        sorter: true,
        render: false,
      },
      {
        title: 'OOS_OOS',
        dataIndex: 'oos',
        width: 100,
        blankWidth: 100,
        render: false,
        className: 'tit-right'
      },
      {
        title: 'OOS_lostSales',
        dataIndex: 'lostSales',
        width: 110,
        blankWidth: 110,
        render: (value) => value === null || value === 0 ? '$0.00' : value && `$${value.toLocaleString('zh', { minimumFractionDigits: 2 })}`,
        className: 'tit-right'
      },
      {
        title: 'preShipDirSubs',
        dataIndex: 'preShipDirSubs',
        width: 120,
        blankWidth: 120,
        render: (value) => value === null || value === 0 ? '0.00%' : value && `${value.toLocaleString('zh', { minimumFractionDigits: 2 })}%`,
        className: 'tit-right',
      },
      {
        title: 'preShipAllSubs',
        dataIndex: 'preShipAllSubs',
        width: 120,
        blankWidth: 120,
        render: (value) => value === null || value === 0 ? '0.00%' : value && `${value.toLocaleString('zh', { minimumFractionDigits: 2 })}%`,
        className: 'last-col-padding',
      }]
    },
    order: {
      level: 3,
      columns: [{
        title: 'site',
        dataIndex: 'site',
        width: 70,
        blankWidth: 70,
        sorter: true,
        render: false,
      },
      {
        title: 'item#',
        dataIndex: 'itemNumber',
        width: 100,
        blankWidth: 100,
        sorter: true,
        render: false,
      },
      {
        title: 'itemDesc',
        dataIndex: 'itemDescription',
        width: 120,
        blankWidth: 120,
        sorter: true,
        render: false,
      },
      {
        title: 'brand',
        dataIndex: 'originalItemBrand',
        width: 110,
        blankWidth: 110,
        sorter: true,
        render: false,
      },
      {
        title: 'orderNumber',
        dataIndex: 'orderNumber',
        width: 120,
        blankWidth: 120,
        sorter: true,
        render: false,
      },
      {
        title: 'OOS_OOS',
        dataIndex: 'oos',
        width: 100,
        blankWidth: 100,
        render: false,
      },
      {
        title: 'OOS_lostSales',
        dataIndex: 'lostSales',
        width: 130,
        blankWidth: 110,
        render: (value) => value === null || value === 0 ? '$0.00' : value && `$${value.toLocaleString('zh', { minimumFractionDigits: 2 })}`,
      },
      {
        title: 'preShipDirSubs',
        dataIndex: 'preShipDirSubs',
        width: 140,
        blankWidth: 140,
        render: (value) => value === null || value === 0 ? '0.00%' : value && `${value.toLocaleString('zh', { minimumFractionDigits: 2 })}%`,
        className: 'tit-right',
      },
      {
        title: 'preShipAllSubs',
        dataIndex: 'preShipAllSubs',
        width: 140,
        blankWidth: 140,
        render: (value) => value === null || value === 0 ? '0.00%' : value && `${value.toLocaleString('zh', { minimumFractionDigits: 2 })}%`,
        className: 'last-col-padding',
      },
      
      {
        title: 'vendorNumber',
        dataIndex: 'vendorNumber',
        width: 130,
        blankWidth: 110,
        sorter: true,
        render: false,
      },
      {
        title: 'vendorName',
        dataIndex: 'vendorName',
        width: 130,
        blankWidth: 110,
        sorter: true,
        render: false,
      }]
    }
  }
];
export const CONST_PATH_LEVEL_MAPPING = [
  {
    path: '',
    level: 0,
  },
  {
    path: 'account-type',
    level: 1,
  },
  { path: 'customer', level: 2 },
  {
    path: 'order',
    level: 3,
  },
  { path: 'order-detail', level: 4 },
];
export const CONST_SUMMARY_PATH_LEVEL_MAPPING =[
  {
    path: '',
    level: 0,
  },
  {
    path: 'site-sales',
    level: 1,
  },
  {
    path: 'account-type',
    level: 2,
  },
  { path: 'customer', level: 3 },
  {
    path: 'order',
    level: 4,
  },
  { path: 'order-detail', level: 5 },
]