import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import SearchFiltersPanel from './SearchFiltersPanel';
import { CommonTable } from '../common';
import { CONST_SUBS_AUDIT_TRAIL_TABLE_COLUMNS } from '../../common/manageAccountsAuditTrailConstants';
import { AUDIT_TRAIL_EXPORT_LIMIT, AUDIT_TRAIL_EXPORT_LIMIT_MSG, CONST_PAGINATION_TEN } from '../../common/constants';
import { useFetchSubsAuditTrailData,useResetSubsAuditTrailData, useSaveSearchConditionData, useExportSubsAuditTrail } from './redux/hooks';
import { renderTableColumns } from '../../common/renderColumns';
import { SearchOutlined, } from '@ant-design/icons';
import { Button, Input, Space, Spin, message } from 'antd';
import { uniqueTerritoryList } from './getOriginalSelectedList';
import { formatTerrirotyData } from './formattedSearchCondition';
import { CORP_USER_LIST } from '../../common/corpProfile';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { datadogRum } from '@datadog/browser-rum';
export default function SubRepositoriesAuditTrail() {
  const {
    subsAuditTrailData,
    fetchSubsAuditTrailDataPending,
    searchConditionData, 
    originalList,
    territoryData,
    userId,
    exportSubsAuditTrailPending,
  } = useSelector(state => ({
    subsAuditTrailData: state.manageSubs.subsAuditTrailData,
    fetchSubsAuditTrailDataPending: state.manageSubs.fetchSubsAuditTrailDataPending,
    searchConditionData: state.manageSubs.searchConditionData,
    originalList: state.manageSubs.originalList,
    territoryData: state.dashboard.territoryData,
    userId: state.home.auth.user.userId,
    exportSubsAuditTrailPending: state.manageSubs.exportSubsAuditTrailPending,
  }));
  const { fetchSubsAuditTrailData }  = useFetchSubsAuditTrailData();
  const { resetSubsAuditTrailData } = useResetSubsAuditTrailData();
  const { saveSearchConditionData } = useSaveSearchConditionData();
  const { exportSubsAuditTrail } = useExportSubsAuditTrail();
  const renderColumnsElement = [
    {},//site placeholder
    { //render Account#
      extendRender: (value, label, record) => {
        if(record.customerNumber && record.customerNumber.length>0){
          return (
            <>
              <div className="col-title">{record.customerNumber}</div>
              <div className="col-desc">{record.customerName}</div>
            </>
          )
        }
        return (
          <>
            <div className="col-title">{value}</div>
            <div className="col-desc">{record.nationalName}</div>
          </>
        )
      }
    },
    {},//originalItem placeholder
    {},//original uom placeholder
    {},//ruleType placeholder
    {}, //sub item placeholder
    {},//log placeholder
    {},//oldValue placeholder
    {},//newValue placeholder
    { //render modified log
      extendRender: (value, label, record) => {
        return value && (
          <>
            <div className="col-title">{record?.username}</div>
            <div className="col-desc">{`${record.userId} ${record.modifiedDate} ${record.modifiedTimestamp}`}</div>
          </>
        )
      }
    },
  ]
  const inputRef = useRef();
  let searchForm = null;
  let clearFiltersRef = null;
  let confirmRef = null;
  const [searchText, setSearchText] = useState('');
  const handleTerritoryParam = (values) => {
    let isCorpProfileUser = false
    if(originalList.length === 0) {
      const user = CORP_USER_LIST.filter(item => item.userId === userId);
      if (!_.isEmpty(user)) {
        isCorpProfileUser = true
      }
    }
    if((territoryData && ((territoryData.role && territoryData.role.toLowerCase() === 'corp') || _.isEmpty(territoryData.role))) || isCorpProfileUser) {
      let temp = values.territoryInfoList ? values.territoryInfoList.trim() : ''
      if(temp) {
        return [{"terrName": temp}]
      }else {
        return []
      }
    }else {
      return values.territoryInfoList !== undefined && values.territoryInfoList !== null ? uniqueTerritoryList(formatTerrirotyData(values, territoryData)) : null
    }
  }
  const handleOriginalItemSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    // setSearchedColumn(dataIndex);
    const formVals = searchForm.getFieldsValue()
    if(formVals && formVals.sites && formVals.sites.length > 0) {
      saveSearchConditionData({
        ...formVals,
        territoryInfoList: handleTerritoryParam(formVals),
      });
    } else {
      message.error('Please select Site!')
    }
  };
  const handleOriginalItemReset = (clearFilters, confirm, dataIndex) => {
    setSearchText('');
    clearFilters();
    confirm({
      closeDropdown: true,
    });
    // setSearchedColumn(dataIndex);
    const formVals = searchForm.getFieldsValue()
    if(formVals && formVals.sites && formVals.sites.length > 0) {
      saveSearchConditionData({
        ...formVals,
        territoryInfoList: handleTerritoryParam(formVals),
      });
    } else {
      message.error('Please select Site!')
    }
  };
  const originalItemSearchReset = () => {
    setSearchText('');
    if(clearFiltersRef) {
      clearFiltersRef()
    }
    if(confirmRef) {
      confirmRef({
        closeDropdown: true,
      });
    }
  };
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      clearFiltersRef = clearFilters;
      confirmRef = confirm;
      return (
        <div style={{ padding: 8, }}>
          <Input
            ref={inputRef}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleOriginalItemSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleOriginalItemSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 80 }}
            >
              Search
            </Button>
            <Button onClick={() => handleOriginalItemReset(clearFilters, confirm, dataIndex)} size="small" style={{ width: 70 }}>
              Reset
            </Button>
          </Space>
        </div>
      )
    },
    filterIcon: filtered => <SearchOutlined style={{ fontSize: '16px', color: filtered ? 'blue' : undefined }} />,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => inputRef.current.select(), 100);
      }
    },
  });
  const searchFormCb = (form) => {
    searchForm = form;
  };
  // CONST_SUBS_AUDIT_TRAIL_TABLE_COLUMNS[2] = {...CONST_SUBS_AUDIT_TRAIL_TABLE_COLUMNS[2], ...getColumnSearchProps('originalItem')};
  let columns = renderTableColumns(CONST_SUBS_AUDIT_TRAIL_TABLE_COLUMNS, null, renderColumnsElement);
  const onExportSubsAuditTrail = ()=>{
    if(subsAuditTrailData.totals > AUDIT_TRAIL_EXPORT_LIMIT) {
      message.error(AUDIT_TRAIL_EXPORT_LIMIT_MSG);
      return;
    }
    const params = {...searchConditionData}
    if(searchText) {
      params.originalItem = searchText
    }
    const context = datadogRum.getGlobalContext('BosView');
    const timing = Date.now();
    exportSubsAuditTrail(params); 
    datadogRum.onReady(function(){
      datadogRum.addTiming(`Export ${context.BosView.screen} data`,timing);
    });
  }
  return (
    <div className="manage-subs-sub-repositories-audit-trail">
      <Spin spinning={exportSubsAuditTrailPending}>
        <div className="chart-title font-size-16-bold" style={{font: "Myriad Bold"}}>Sub Repositories Audit Trail</div>
        <SearchFiltersPanel searchForm={searchFormCb} originalItemSearchReset={originalItemSearchReset}/>
        <div className="flex align-item-center justify-end" style={{ marginBottom: "20px"}}>
          <Button type="link" title="Export" disabled={subsAuditTrailData === null} onClick={onExportSubsAuditTrail} style={{ marginLeft: '-20px' }}>
            <FontAwesomeIcon icon={faFileExport} color={_.isEmpty(searchConditionData) ? "#a1a1a2" : "#6b6b6c"} style={{ height: "20px", width: "20px" }} />
            <p style={{ marginTop: "-5px", fontSize: "11px", marginLeft: "-3px", color: (_.isEmpty(searchConditionData) ? "#a1a1a2" : "#6b6b6c") }}>Export</p>
          </Button>
        </div>
        <CommonTable 
          rowKey={Math.random()}
          columns={columns}
          tableClassName={subsAuditTrailData ? "outs-report-table" : ""}
          datasource={subsAuditTrailData}
          loadingData={fetchSubsAuditTrailDataPending}
          paginationStyle={{textAlign: "right", margin: '16px 0px'}}
          triggerConditions={searchConditionData}
          onFecthData={fetchSubsAuditTrailData}
          onResetStore={resetSubsAuditTrailData}
          scrollStyle={{ x: 'max-content', y: 300 }}
          paginationSetting={CONST_PAGINATION_TEN}
          searchText={searchText}
        />
      </Spin>
    </div>
  );
};

SubRepositoriesAuditTrail.propTypes = {};
SubRepositoriesAuditTrail.defaultProps = {};
