import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import _, { values } from 'lodash';
import moment from 'moment';
import { Row, Col, Form, Select, Button, DatePicker, Input, Spin, Empty, Tooltip, Radio, Switch, Checkbox,} from 'antd';
import {
  CONST_ALL_RULE_OPTIONS,
  CONST_CATEGORY_OPTIONS,
  CONST_MANAGED_BY_OPTIONS,
  CONST_MAINTAIN_ACCOUNTS_PATH_NAME,
  CONST_MANAGE_OUTS_PATH_NAME,
  CONST_MANAGE_SUBS_PATH_NAME,
  CONST_BOS_ROOT_PATH_NAME,
  CONST_CUSTOMER_GROUP_PATH_NAME,
  CONST_MANAGE_ACCOUNTS_AUDIT_TRAIL_PATH_NAME,
  CONST_SUB_REPOSITORIES_AUDIT_TRAIL_PATH_NAME,
  CONST_CREATE_GROUPS_AUDIT_TRAIL_PATH_NAME,
  CONST_OGM_SUBS_PATH_NAME,
} from '../../common/manageSubsConstants';
import { SELECT_ALL_OPTION, SLASH_DATE_FORMAT, COMMON_DATE_FORMAT, BOS_SEARCH_CONDITION_KEY, SIA_TEST_USER_ID_KEY, BOS_TERRITORY_DATA_KEY } from '../../common/constants';
import {
  useSaveSearchConditionData,
  useResetSearchConditionData,
  useResetOutsData,
  useFetchSearchPanelOrgInfo,
  useSaveInitTerritoryFilters,
  useRetainConditionData,
  useResetConditionData,
  useResetDropoutsData,
  useSaveOrgDropdownList,
  useSaveIsCorpProfileUser,
  useFetchGroupIdsData,
  useResetGroupIdsData,
  useFetchBosTerritoryData,
} from './redux/hooks';
import { useFetchConceptIdsData, useResetConceptIdsData } from '../dashboard/redux/hooks';
import { uniqueTerritoryList, getOrgSelectedList,getTerritoryList } from './getOriginalSelectedList';
import { useDebounce } from '../../common/hook';
import { CONST_ROLE_OPTIONS_CCC, CONST_ROLE_OPTIONS_CST, CONST_ROLE_OPTIONS_LOCAL } from '../../common/manageOutsConstants';
import { formatTerrirotyData } from './formattedSearchCondition';
import { CORP_USER_LIST } from '../../common/corpProfile';
import { GROUP_ID_PREFIX } from '../../common/manageGroupsConstants';
export default function SearchFiltersPanel({ searchForm, originalItemSearchReset, isOnClickRefresh, resetSortedInfo,setOptimizationEnable, searchText}) {
  const { conceptData, fetchConceptIdsDataPending, userId, fetchTerritoryDataPending, fetchSearchPanelOrgInfoPending, searchConditionData,conditionData,
    savedOrgDropdownList,isCorpProfileUser,groupIdsData,fetchGroupIdsDataPending,bosTerritory,
   } = useSelector(state => ({
    conceptData: state.dashboard.conceptData,
    fetchConceptIdsDataPending: state.dashboard.fetchConceptIdsDataPending,
    userId: state.home.auth.user.userId,
    fetchTerritoryDataPending: state.dashboard.fetchTerritoryDataPending,
    fetchSearchPanelOrgInfoPending: state.manageSubs.fetchSearchPanelOrgInfoPending,
    searchConditionData: state.manageSubs.searchConditionData,
    //merged Melody's code
    conditionData: state.manageSubs.conditionData,
    enabledSites: state.manageSubs.enabledSites,
    savedOrgDropdownList:state.manageSubs.orgDropdownList,
    isCorpProfileUser:state.manageSubs. isCorpProfileUser,
    groupIdsData: state.manageSubs.groupIdsData,
    fetchGroupIdsDataPending: state.manageSubs.fetchGroupIdsDataPending,
    bosTerritory: state.manageSubs.bosTerritoryData,
  }));
  const { saveSearchConditionData } = useSaveSearchConditionData();
  const { fetchConceptIdsData } = useFetchConceptIdsData();
  const { fetchBosTerritoryData } = useFetchBosTerritoryData();
  const { fetchSearchPanelOrgInfo, originalList, originalConstData } = useFetchSearchPanelOrgInfo();
  const { initTerritoryFilters, saveInitTerritoryFilters } = useSaveInitTerritoryFilters();
  const [orgSelectedList, setOrgSelectedList] = useState(originalConstData);
  const [orgDropdownList, setOrgDropdownList] = useState(originalConstData);
  const { resetSearchConditionData } = useResetSearchConditionData();
  const { resetConditionData } = useResetConditionData();
  const { resetConceptIdsData } = useResetConceptIdsData();
  const { resetOutsData } = useResetOutsData();
  const { retainConditionData } = useRetainConditionData();
  const { resetDropoutsData } = useResetDropoutsData();
  const { saveOrgDropdownList } = useSaveOrgDropdownList();
  const { saveIsCorpProfileUser } = useSaveIsCorpProfileUser();
  const { fetchGroupIdsData } = useFetchGroupIdsData();
  const { resetGroupIdsData } = useResetGroupIdsData();
  const location = useLocation();
  const [searchConceptKey, setSearchConceptKey] = useState('');
  const [territoryList, setTerritoryList] = useState([]);
  const [form] = Form.useForm();
  const [managedByValue, setManagedByValue] = useState();
  const [managedByDefaultValue, setManagedByDefaultValue] = useState();
  const [checkedMyGroups,setCheckedMyGroups] = useState(false);
  // const [isCorpProfileUser, setCorpProfileUser] = useState(false);
  const [isClickReset, setClickReset] = useState(false);
  const [searchGroupIdKey, setSearchGroupIdKey] = useState('');
  const bosTerritoryData = JSON.parse(sessionStorage.getItem(BOS_TERRITORY_DATA_KEY));
  useEffect(() => {
    let user_id = userId;
    let sessionUserId = sessionStorage.getItem(SIA_TEST_USER_ID_KEY);
    if(!_.isEmpty(sessionUserId)){
      if(sessionUserId !== user_id){
        user_id = sessionUserId; 
      }
    }
    const user = CORP_USER_LIST.filter(item => item.userId === user_id);
    if (!_.isEmpty(user)) {
      saveIsCorpProfileUser(true);
    }else {
      saveIsCorpProfileUser(false);
    }
    if (originalList.length === 0) {
      let role;
      let defaultMarket = null;
      let defaultRegion = null;
      let defaultSiteObj = null;
      let defaultSite = null;
      // let initOrgSelectedList = {};
      let defaultFormValues = [];
      let territories = [];
      let initOrgList = {};
      let defaultOrgDropdownList = {};
      let defaultOrgSelectedList = {
        market: defaultMarket,
        region: defaultRegion,
        sites: defaultSite,
      };
      if (!_.isEmpty(user)) {
        // setCorpProfileUser(true);
        saveIsCorpProfileUser(true);
        role = 'Corp';
        setTerritoryList(territories);
        fetchSearchPanelOrgInfo(defaultMarket, defaultRegion, defaultSiteObj).then(orgData => {
          initOrgList = orgData.originalList;
          defaultOrgDropdownList = orgData.originalConstData;
          //if corp user set default org selected market and region otherwise all option not working
          defaultOrgSelectedList ={
            ...defaultOrgSelectedList,
            market: defaultOrgDropdownList.market,
            region: defaultOrgDropdownList.region,
          }
          setInitailValue(defaultOrgDropdownList, defaultFormValues, role, defaultOrgSelectedList, initOrgList, territories);
        });
      } else {
        fetchBosTerritoryData({userId, "isSpecial": true}).then(territoryData => {
          // setCorpProfileUser(false);
          saveIsCorpProfileUser(false);
          territories = uniqueTerritoryList(territoryData.territories);
          setTerritoryList(territories || []);
          role = territoryData.role;
          defaultMarket = territoryData.market ? territoryData.market.split() : null;
          defaultRegion = territoryData.region ? territoryData.region.split() : null;
          defaultSiteObj = territoryData.sites;
          defaultSite = territoryData.sites
            ? territoryData.sites.map(item => item.site_nbr)
            : null;
          defaultOrgSelectedList = {
            market: defaultMarket,
            region: defaultRegion,
            sites: defaultSite,
          };
          fetchSearchPanelOrgInfo(defaultMarket, defaultRegion, defaultSiteObj).then(orgData => {
            initOrgList = orgData.originalList;
            defaultOrgDropdownList = orgData.originalConstData;
            defaultFormValues =
              role === 'Market'
                ? defaultMarket
                : role === 'Region'
                  ? defaultRegion
                  : role === 'Site' || role === 'MA'
                    ? defaultSite
                    : [];
            let newRole =
              role === 'Site' ? 'Sites' : role;
              //if corp user set default org selected market and region otherwise all option not working
              if(role === 'Corp' || !role || role.length === 0){
                defaultOrgSelectedList ={
                  ...defaultOrgSelectedList,
                  market: defaultOrgDropdownList.market,
                  region: defaultOrgDropdownList.region,
                }
              }
            setInitailValue(defaultOrgDropdownList, defaultFormValues, newRole, defaultOrgSelectedList, initOrgList, territories);
          });
        });
      }
    }
  }, []); // eslint-disable-line
  // Create corp profile to store corp user. Freeze site and territory
  const setInitailValue = (defaultOrgDropdownList, defaultFormValues, newRole, defaultOrgSelectedList, initOrgList, territories) => {
    let initOrgSelectedList = JSON.parse(JSON.stringify(defaultOrgDropdownList));
    initOrgSelectedList.sites = initOrgSelectedList.sites.map(item => item.site_nbr);
    setOrgDropdownList(defaultOrgDropdownList);
    const newOrgList = getOrgSelectedList(
      defaultFormValues,
      newRole,
      defaultOrgSelectedList,
      initOrgList,
      defaultOrgDropdownList,
      setOrgDropdownList,
      territories,
      setTerritoryList,
      territories,
      conceptData,
    );
    initOrgSelectedList = newOrgList.newOrgSelectedList;
    saveInitTerritoryFilters(initOrgSelectedList);//COT-9353: SIA+ Screen refresh issue. after refreshed if click on reset,reset all fields. saved init data,otherwise will fillout by session data
    setOrgSelectedList(initOrgSelectedList);
    //  COT-9353: SIA+ Screen refresh issue,if refresh screen, will set values from storage
    let storagedCondition = JSON.parse(sessionStorage.getItem(BOS_SEARCH_CONDITION_KEY));
     if(!_.isEmpty(storagedCondition)){
       initOrgSelectedList = {...storagedCondition,deliveryDate: moment(storagedCondition?.deliveryDate)};
     }
    
    //initial form default Values: Region Role, Site Role, MA Role
    form.setFieldsValue(initOrgSelectedList);
    if(_.isEmpty(conditionData) && !_.isEmpty(storagedCondition)){
      const dom = document.getElementById('searchBtn');
      if(dom){
        dom.click();
      }
    }
  }
  useEffect(() => {
    //Create corp profile to store corp user. Freeze site and territory
    const user = CORP_USER_LIST.filter(item => item.userId === userId);
    if(!_.isEmpty(user)){
      managedByOption(CONST_ROLE_OPTIONS_CCC);
    }else if (bosTerritory !== null) {
      if (_.isEmpty(bosTerritory.role) || bosTerritory.role.toLowerCase() === 'corp') { //corp user can choose CCC/CST/LOCAL and view CCC/CST/LOCAL data, not corp user only can view local data,if role is null or empty,it is corp user
        managedByOption(CONST_ROLE_OPTIONS_CCC);
      } else {
        managedByOption(CONST_ROLE_OPTIONS_LOCAL);
      }
    }
    else {
      managedByOption(CONST_ROLE_OPTIONS_CCC);
    }
  }, [bosTerritory]); // eslint-disable-line
  const managedByOption=(managedBy)=>{
    let managedByValue = managedBy;
    setManagedByDefaultValue(managedByValue); //COT-9353: SIA+ Screen refresh issue. defaultValue determined by user role.
    let storagedCondition = JSON.parse(sessionStorage.getItem(BOS_SEARCH_CONDITION_KEY));
    if(storagedCondition?.managedBy){
      managedByValue = storagedCondition?.managedBy;
    }
    setManagedByValue(managedByValue);
    form.setFieldsValue({ managedBy: managedByValue });
  }
  //Create corp profile to store corp user. Freeze site and territory.switch menu will retatin the filters
  //merged by Melody's code
  const retainFilterValues = (filterValues)=>{
    form.setFieldsValue({
      sites: filterValues.sites,
      territoryInfoList: filterValues.territoryInfoList,
      nationalIds: filterValues.nationalIds,
      customerNumber: filterValues.customerNumber,
      itemCategory: filterValues.itemCategory,
      ruleType: filterValues?.ruleType,
      groupIds: filterValues?.groupIds,
      accountManagedBy: filterValues?.accountManagedBy,
      groupDescription: filterValues?.groupDescription,
      conceptOrCustomer: filterValues?.conceptOrCustomer,
    })
    if(filterValues?.deliveryDate){
      form.setFieldsValue({deliveryDate: moment(filterValues?.deliveryDate)}); //COT-9353: SIA+ Screen refresh issue. defect tracker,if change deliveryDate, then change site, deliveryDate value will rollback.so need retain deliveryDate 
    }
    if(filterValues?.checkedMyGroups){
      setCheckedMyGroups(filterValues?.checkedMyGroups);
    }
    //when switch menu should  retain territory
    setOrgSelectedList({...orgSelectedList,...filterValues});
    //set Territory List
    const uniqueTerritory = bosTerritoryData ? uniqueTerritoryList(bosTerritoryData.territories) : null
    const getSpecificTerritory = getTerritoryList(!_.isEmpty(filterValues.sites)?filterValues.sites:[],uniqueTerritory);
    const territories = !_.isEmpty(getSpecificTerritory)?getSpecificTerritory:territoryList;
    setTerritoryList(territories || []);
    //when switch menu should trigger national id and customer validation.
    updateValidator();
  }
  useEffect(() => {
    let storagedCondition = JSON.parse(sessionStorage.getItem(BOS_SEARCH_CONDITION_KEY));
    if (conditionData) {
      retainFilterValues(conditionData);
    } else if (!_.isEmpty(storagedCondition)) {
      retainFilterValues(storagedCondition);
    } else if (!isClickReset) {
      form.setFieldsValue(initTerritoryFilters);
      setOrgSelectedList(initTerritoryFilters);
      const territories = bosTerritoryData ? uniqueTerritoryList(bosTerritoryData.territories) : null;
      setTerritoryList(territories || []);
    }
  },[conditionData]);// eslint-disable-line
  //defect:cot-5316,when click reload will reset ccc/cst local option as same as search condition
  useEffect(() => {
    if (!_.isEmpty(searchConditionData)) {
      setManagedByValue(searchConditionData.managedBy);
    }
  }, [isOnClickRefresh, searchConditionData]); // eslint-disable-line
  const updateValidator = useCallback(() => {
    form.validateFields();
  }, [form.getFieldsValue()]);  // eslint-disable-line

  useEffect(() => {
    saveOrgDropdownList(orgDropdownList)
  }, [orgDropdownList]); // eslint-disable-line
  const onDebounceSearchGroupID = (value) => {
    setSearchGroupIdKey(value);
    handleSearchGroupIds(value);
  };
  const handleSearchGroupIds = useDebounce((value) => {
    if (value.length >= 4) {
      const { sites } = form.getFieldsValue();
      fetchGroupIdsData(sites,'##'+value.toUpperCase().substr(0, 6), value.toUpperCase());
    }
  }, 500);
  const handleGroupIdsFilter = (inputValue, option) => {
    return (option.value === 'All' || option.value.toUpperCase().includes(inputValue.trim().toUpperCase())) ? true : false;
  }
  const groupIdsValidator = (rule, value, callback) => {
    if (searchGroupIdKey !== undefined && searchGroupIdKey.length > 2 && searchGroupIdKey.indexOf(GROUP_ID_PREFIX) !== 0) {
      callback(`Please input string begins with ##!`);
    }
    callback();
  }
  const requiredSomeoneValidator = (rule, value, callback) => {
    if(isGroupsAuditTrail){ //COT-9212:BOS2.0 - UI - Audit trail of create groups, groups don't have customer#, so remove Concept ID and Ship To conbined validation
      callback();
      return;
    }
    const { nationalIds, customerNumber } = form.getFieldsValue();
    if (nationalIds !== undefined && nationalIds.length > 0 && customerNumber !== undefined && customerNumber.length > 0) {
      callback(`Please select either Concept ID or Ship To Number!`);
    }
    if(location.pathname === CONST_MANAGE_ACCOUNTS_AUDIT_TRAIL_PATH_NAME || location.pathname === CONST_SUB_REPOSITORIES_AUDIT_TRAIL_PATH_NAME){
      if((nationalIds === undefined || nationalIds.length === 0) && (customerNumber === undefined || customerNumber.length === 0)){
        callback(`Either group  or ship to is a mandatory search condition!`);
      }
    }
    callback();
  };
  //COT-9296:BOS 3.0 - UI - Feedback of audit trail, if group has been deleted, still can be searched in audit trail screen, so need display deleted group in concept id/gruop dropdown, if isFrom is auditTrail,  similarGroupId interface will include the deleted group
  const isFrom = location.pathname === CONST_MANAGE_ACCOUNTS_AUDIT_TRAIL_PATH_NAME || location.pathname === CONST_SUB_REPOSITORIES_AUDIT_TRAIL_PATH_NAME || location.pathname === CONST_CREATE_GROUPS_AUDIT_TRAIL_PATH_NAME ? 'auditTrail' : '';
  const handleSearch = useDebounce((value) => {
    if (value.length >= 4) {
      const { sites } = form.getFieldsValue();
      if(/^[0-9]+$/.test(value) && location.pathname === CONST_OGM_SUBS_PATH_NAME){
        form.setFieldsValue({conceptOrCustomer: value});
        retainConditionData({ ...conditionData, conceptOrCustomer: value });
        return;
      }
      fetchConceptIdsData(value.toUpperCase().substr(0, 4), value.toUpperCase(),sites,isFrom);
    }
  }, 500);
  const validateCustomerNumber = (rule, value, callback) => {
    const { conceptOrCustomer } = form.getFieldsValue();
    if (conceptOrCustomer !== undefined && conceptOrCustomer.length > 0 && /^[0-9]+$/.test(conceptOrCustomer)) {
      if(conceptOrCustomer.length != 6){
        callback('Please input 6 digits!');
      }
      callback();
    }
    callback();
  }
  const onDebounceSearchConceptID = (value) => {
    setSearchConceptKey(value);
    handleSearch(value);
  };

  const selectAllOptions = (data, columnName) => {
    const allOptions = data.map(item => {
      return columnName ? item[columnName] : item;
    });
    return allOptions;
  }
  const handleSelectChange = (value, label) => {
      const objForm = form.getFieldsValue();
      const lastedSelectOption = location.pathname === CONST_OGM_SUBS_PATH_NAME && label === 'conceptOrCustomer' ? undefined : value[value.length - 1];
      let newSelectedList = value;
      // if (lastedSelectOption === SELECT_ALL_OPTION) {
      //   if (label === 'sites') {
      //     newSelectedList = selectAllOptions(orgDropdownList.sites, 'site_nbr');
      //     setOrgSelectedList({...orgSelectedList,[label]:newSelectedList});
      //   } else if (label === 'nationalIds') {
      //     newSelectedList = selectAllOptions(conceptData.filter(item => item.nationalId.toLowerCase().includes(searchConceptKey.trim().toLowerCase())), 'nationalId');
      //     setSearchConceptKey('');
      //   } else if (label === 'territoryInfoList') {
      //     newSelectedList = selectAllOptions(territoryList, 'terrName');
      //     setOrgSelectedList({...orgSelectedList,[label]:newSelectedList});
      //   } else if(label === 'itemCategory') {
      //     newSelectedList = selectAllOptions(CONST_CATEGORY_OPTIONS, 'key');
      //   } else if(label === 'accountManagedBy') {
      //     newSelectedList = selectAllOptions(CONST_MANAGED_BY_OPTIONS, 'key');
      //   }
      // }
      if(label === 'sites' || label === 'territoryInfoList'){
        const newList = getOrgSelectedList(
          value,
          label,
          orgSelectedList,
          originalList,
          orgDropdownList,
          setOrgDropdownList,
          !_.isEmpty(bosTerritoryData)?bosTerritoryData.territories:null,
          setTerritoryList,
          territoryList,
          conceptData,
          true,
        );
        setOrgSelectedList(newList.newOrgSelectedList);
        if(isCorpUser()) {
          if(label === 'sites'){
            form.setFieldsValue({[label]: newList.newOrgSelectedList[label]});
            retainConditionData({...conditionData, [label]: newList.newOrgSelectedList[label]});
          }else{
            form.setFieldsValue({[label]: value});
            retainConditionData({...conditionData, [label]: value});
          }
        }else {
          form.setFieldsValue(newList.newOrgSelectedList);
          if(label === 'sites'){
            retainConditionData({...conditionData, [label]: newList.newOrgSelectedList[label], territoryInfoList: newList.newOrgSelectedList.territoryInfoList});
          }else{
            let territoryList = newList.newOrgSelectedList[label]
            if(conditionData && conditionData.sites && conditionData.sites.length > 0) {
              retainConditionData({...conditionData, [label]: territoryList});
            }else {
              retainConditionData({...conditionData, sites: newList.newOrgSelectedList.sites, [label]: territoryList});
            }
          }
        }
      }else{
        if (lastedSelectOption === SELECT_ALL_OPTION) {
          if (label === 'nationalIds') {
            // newSelectedList = selectAllOptions(conceptData.filter(item => item.nationalId.toLowerCase().includes(searchConceptKey.trim().toLowerCase())), 'nationalId');
              newSelectedList = conceptData.filter(item => item.toLowerCase().includes(searchConceptKey.trim().toLowerCase()));
              setSearchConceptKey('');
            } else if(label === 'itemCategory') {
              newSelectedList = selectAllOptions(CONST_CATEGORY_OPTIONS, 'key');
            } else if(label === 'accountManagedBy') {
              newSelectedList = selectAllOptions(CONST_MANAGED_BY_OPTIONS, 'key');
            } else if(label === 'groupIds'){
              newSelectedList = groupIdsData.filter(item => item.toUpperCase().includes(searchGroupIdKey.trim().toUpperCase()));
            }
        }
      //merged Melody's code
        form.setFieldsValue({ ...objForm, [label]: newSelectedList });
       //if not change site and terrritory will set default site and territory when switch menu,because ma role has orgSelected site and territory 
        const orgSelectSiteList = pushOrgSelectSiteList();
        retainConditionData({ ...conditionData, [label]: newSelectedList,...orgSelectSiteList });
    }
  };

  const handleInputChg = (value, label) => {
    const orgSelectSiteList = pushOrgSelectSiteList();
    //if not change site and terrritory will set default site and territory when switch menu,because ma role has orgSelected site and territory 
    retainConditionData({ ...conditionData, [label]: value, ...orgSelectSiteList });
  };
  const pushOrgSelectSiteList = () => {
    const orgSelectSites = orgSelectedList.sites.length !== 0 ?
      orgSelectedList.sites : conditionData && conditionData.sites ?
        conditionData.sites : [];
    if(isCorpUser()) {
      return {
        sites: orgSelectSites,
      }
    }else {
      const orgSelectTerritory = orgSelectedList.territoryInfoList.length !== 0 ?
        orgSelectedList.territoryInfoList : conditionData && conditionData.territoryInfoList ?
          conditionData.territoryInfoList : [];
      return {
        sites: orgSelectSites,
        territoryInfoList: orgSelectTerritory,
      }
    }
  }
  const handleConceptFilter = (inputValue, option) => {
    return (option.value === 'All' || option.value.toLowerCase().includes(inputValue.trim().toLowerCase())) ? true : false;
  }

  const handleCategoryFilter = (inputValue, option) => {
    return option.children.toLowerCase().includes(inputValue.trim().toLowerCase()) ? true : false;
  }

  const handleManagedByFilter = (inputValue, option) => {
    return option.children.toLowerCase().includes(inputValue.trim().toLowerCase()) ? true : false;
  }
  const handleGroupId = (value,label)=>{
    //appendHash
    // if(value.indexOf('##') < 0){
    //   form.setFieldsValue({[label]:'##'+value});
    // }
  }

  const isCorpUser = () => {
    if((bosTerritoryData && ((bosTerritoryData.role && bosTerritoryData.role.toLowerCase() === 'corp') || _.isEmpty(bosTerritoryData.role))) || isCorpProfileUser) {
      return true
    }else {
      return false
    }
  }

  const handleTerritoryParam = (values) => {
    if(isCorpUser()) {
      let temp = values.territoryInfoList ? values.territoryInfoList.trim() : ''
      if(temp) {
        return [{"terrName": temp}]
      }else {
        return []
      }
    }else {
      return values.territoryInfoList !== undefined && values.territoryInfoList !== null ? uniqueTerritoryList(formatTerrirotyData(values, bosTerritoryData)) : null
    }
  }

  const getOutsFormData = (values) => {
    const outsFormData = {
      ...values,
      deliveryDate: values.deliveryDate !== undefined && values.deliveryDate !== null ? parseInt(moment(values.deliveryDate).format(COMMON_DATE_FORMAT)) : null,
      customerNumber: values.customerNumber === '' ? null : values.customerNumber,
      territoryInfoList: handleTerritoryParam(values),
    };
    return outsFormData
  }

  const onFinish = values => {
    let storagedCondition = JSON.parse(sessionStorage.getItem(BOS_SEARCH_CONDITION_KEY));
    retainConditionData({ ...storagedCondition, ...values, originalItem: searchText ? searchText : storagedCondition?.originalItem });//COT-9353: SIA+ Screen refresh issue.if switch to screen that don't have originalItem field, will lose originalItem field
    switch (location.pathname) {
      case CONST_MAINTAIN_ACCOUNTS_PATH_NAME:
        const accountsFormData = {
          ...values,
          managedBy: values.accountManagedBy,
          territoryInfoList: handleTerritoryParam(values),
          // userId,
          // timestamp: moment().format(DATE_TIME_TWO_FORMAT),    //COT-9309:BOS 3.0 - UI - Feedback of audit trail. remove those 2 attributes,as don't need to collect search event.
        }
        delete accountsFormData.accountManagedBy
        saveSearchConditionData(accountsFormData);
        return;
      case CONST_BOS_ROOT_PATH_NAME:
      case CONST_MANAGE_OUTS_PATH_NAME:
        saveSearchConditionData(getOutsFormData(values));
        return;
      case CONST_MANAGE_SUBS_PATH_NAME:
        const formData = {
          ...values,
          ruleType: values.ruleType !== undefined ? values.ruleType : 'all',
          territoryInfoList: handleTerritoryParam(values),
        };
        saveSearchConditionData(formData);
        return;
      case CONST_CUSTOMER_GROUP_PATH_NAME:
        let groupFormData = values;
        if(checkedMyGroups) groupFormData={...values, userId:userId}
        retainConditionData({...storagedCondition,...values,checkedMyGroups:checkedMyGroups});
        saveSearchConditionData({...groupFormData});
        return;
      case CONST_MANAGE_ACCOUNTS_AUDIT_TRAIL_PATH_NAME:
        saveSearchConditionData({...values});
        return;
      case CONST_SUB_REPOSITORIES_AUDIT_TRAIL_PATH_NAME:
        saveSearchConditionData({...values});
        return;
      case CONST_CREATE_GROUPS_AUDIT_TRAIL_PATH_NAME:
        let groupAuditTrail = values;
        if(checkedMyGroups) groupAuditTrail={...values, userId : userId}
        saveSearchConditionData({...groupAuditTrail});
        return;
      case CONST_OGM_SUBS_PATH_NAME:
        let ogmSubsCondition = { ...values,ruleType: values.ruleType !== undefined ? values.ruleType : 'all' };
        ogmSubsCondition.conceptOrCustomer = Array.isArray(values.conceptOrCustomer) && values.conceptOrCustomer.length > 0
          ? values.conceptOrCustomer[0]
          : values.conceptOrCustomer;
        saveSearchConditionData(ogmSubsCondition);
        return;
      default: //CONST_ADMIN_CONSOLE_PATH_NAME
        return;
    }
  };
  const onReset = () => {
    if(resetSortedInfo) {
      resetSortedInfo()
    }
    form.resetFields();
    resetSearchConditionData();
    //merged Melody's code
    resetConditionData();
    if(originalItemSearchReset) {
      originalItemSearchReset()
    }
    //add by jennifer reset CCC/CST LOCAL
    setManagedByValue(managedByDefaultValue);
    setClickReset(!isClickReset);
    // setOptimizationEnable(true);   //COT-8330
    setCheckedMyGroups(false);
    //COT-9353: SIA+ Screen refresh issue
    sessionStorage.removeItem(BOS_SEARCH_CONDITION_KEY);
    setOrgSelectedList(initTerritoryFilters);
  };
  const onChange = (e) => {
    resetOutsData();
    resetDropoutsData();
    setManagedByValue(e.target.value);
    form.setFieldsValue({ managedBy: e.target.value });
    form.validateFields(['sites']).then(() => {
      let formVals = form.getFieldsValue()
      if(formVals && formVals.sites && formVals.sites.length > 0) {
        saveSearchConditionData(getOutsFormData(formVals));
        retainConditionData({...conditionData,...formVals});
      }
    }).catch(() => {
      //nothing to do
    })
  }

  const disablePastDt = current => {
    return current.isBefore(moment().subtract(1, 'day'));
  };
  const isShowTerritory = location.pathname === CONST_BOS_ROOT_PATH_NAME || location.pathname === CONST_MAINTAIN_ACCOUNTS_PATH_NAME || location.pathname === CONST_MANAGE_SUBS_PATH_NAME || location.pathname === CONST_MANAGE_OUTS_PATH_NAME;
  const isGroupsAuditTrail = location.pathname === CONST_CREATE_GROUPS_AUDIT_TRAIL_PATH_NAME ? true : false;//COT-9212:BOS2.0 - UI - Audit trail of create groups, groups don't have customer# and align search button right
  const wrapperCol = (location.pathname === CONST_MANAGE_SUBS_PATH_NAME || location.pathname === CONST_MANAGE_OUTS_PATH_NAME ||
    location.pathname === CONST_BOS_ROOT_PATH_NAME) ? { span: 3 } : { span: 4 };
  const wrapperColItem = (location.pathname === CONST_MANAGE_SUBS_PATH_NAME || location.pathname === CONST_MANAGE_OUTS_PATH_NAME ||
    location.pathname === CONST_BOS_ROOT_PATH_NAME) ? { span: 7 } : isGroupsAuditTrail ? {span: 4} : { span: 8 };
  const wrapperColAction = location.pathname === CONST_MAINTAIN_ACCOUNTS_PATH_NAME ? { span: 4 } : { span: 4 };
  const offsetColAction = location.pathname === CONST_CUSTOMER_GROUP_PATH_NAME || location.pathname === CONST_SUB_REPOSITORIES_AUDIT_TRAIL_PATH_NAME || isGroupsAuditTrail ? { offset: 4 } : location.pathname === CONST_OGM_SUBS_PATH_NAME ? { offset: 7 } : !isShowTerritory ? { offset: 8 } : '';
  if (searchForm) searchForm(form)
  let selectMode = (!isCorpUser() && (location.pathname === CONST_MANAGE_OUTS_PATH_NAME || location.pathname === CONST_BOS_ROOT_PATH_NAME)) ? 'tags' : 'multiple'
 const handlePressEnter =(e)=>{
  // if(e.keyCode === 13){
  //   e.preventDefault();
  //   e.stopPropagation();
  // }
  // document.getElementsByName('manage-subs-filter-form')[0].submit();
  // console.log('ddddd');
 }
 const validateDescription =(rule,value,callback)=>{
    if (value && value.length < 4) {
      callback('Please input at least 4 characters.');
    }
      callback();
 }
 let storagedCondition = JSON.parse(sessionStorage.getItem(BOS_SEARCH_CONDITION_KEY));
 let RULE_TYPE_OPTIONS = CONST_ALL_RULE_OPTIONS;
 const RULE_TYPE_OGM_NO_SUBS = { label: 'No Rules', key: 'norules'};
 if(location.pathname === CONST_OGM_SUBS_PATH_NAME){
    RULE_TYPE_OPTIONS = CONST_ALL_RULE_OPTIONS.filter(item => item.key !== 'dropout');
   RULE_TYPE_OPTIONS.splice(3, 0, RULE_TYPE_OGM_NO_SUBS);
 }
  return (
    <div className="manage-subs-search-filters-panel">
      <Spin spinning={fetchTerritoryDataPending || fetchSearchPanelOrgInfoPending} tip="Loading...">
        <Form
          form={form}
          name="manage-subs-filter-form"
          className="filter-form"
          onFinish={onFinish}
          initialValues={{
            deliveryDate: moment().add(1, 'days'),
            managedBy: managedByDefaultValue,
            // optimizationEnable: true,   //COT-8330
            originalItem: storagedCondition?.originalItem,
          }}
        >
          <Row gutter={12}>
            <Col span={4}>
              <Form.Item name="sites" rules={[{ required: true, message: 'Please select Site!' }]}>
                <Select
                  mode="multiple"
                  placeholder="Site"
                  allowClear
                  maxTagCount={1}
                  maxTagTextLength={3}
                  onChange={value => handleSelectChange(value, 'sites')}
                  // disabled={
                    // orgSelectedList.region.length === 0 ||
                    // (territoryData && territoryData.role && territoryData.role.toLowerCase() === 'site')
                  // }
                  onInputKeyDown={e=>handlePressEnter(e)}
                >
                  
                  <Select.Option key={SELECT_ALL_OPTION} value={SELECT_ALL_OPTION}>
                    All Sites
                  </Select.Option>
                  {savedOrgDropdownList.sites.length > 0 && savedOrgDropdownList.sites.map(item => {
                    const siteNbr = item.site_nbr;
                    const siteNbrName = item.site_desc;
                    return (
                      <Select.Option key={siteNbr} value={siteNbr}>
                        {`${siteNbr} - ${siteNbrName}`}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            {(isShowTerritory) &&( 
              <Col {...wrapperCol}>
                {
                  isCorpUser() ?
                  (
                    <Form.Item name="territoryInfoList">
                      <Input placeholder="Territory" onChange={e => handleInputChg(e.target.value, 'territoryInfoList')} />
                    </Form.Item>
                  ) :
                  (
                    <Form.Item name="territoryInfoList"
                      rules={[
                        {
                          required: bosTerritoryData && bosTerritoryData.role !== null && bosTerritoryData.role.toLowerCase() === 'ma',
                          message: 'Please select Territory!',
                        },
                      ]}
                    >
                      <Select
                        mode={selectMode}
                        placeholder="Territory"
                        allowClear
                        maxTagCount={1}
                        maxTagTextLength={10}
                        onChange={(value, option) => handleSelectChange(value, 'territoryInfoList')}
                        //corp user territories is null, disabled this as same as dashborad page
                        disabled={(!_.isEmpty(orgSelectedList) && orgSelectedList.sites && orgSelectedList.sites.length === 0)}
                      >
                        {territoryList ? (
                          <>
                            {
                              <Select.Option key={SELECT_ALL_OPTION} value={SELECT_ALL_OPTION}>
                                All Territories
                              </Select.Option> 
                            }
                            {territoryList.length > 0 && territoryList.map(item => {
                              return (
                                <Select.Option key={item.terrId} value={item.terrName}>
                                  {item.terrName}
                                </Select.Option>
                              );
                            })}
                          </>) : null}
                      </Select>
                    </Form.Item>
                  )
                }
              </Col>)}
            {location.pathname !== CONST_CUSTOMER_GROUP_PATH_NAME && location.pathname !== CONST_OGM_SUBS_PATH_NAME && (
            <Col {...wrapperColItem}>
              <Form.Item name="multValidator" onChange={updateValidator} rules={[{ validator: (rule, value, callback) => requiredSomeoneValidator(rule, value, callback) }]}>
                <Tooltip placement="top" title='Please input 4 char to retrive Concept ID.'>
                  <Form.Item name="nationalIds"
                    style={{ display: 'inline-flex', width: isGroupsAuditTrail? '100%' : 'calc(50% - 8px)', marginRight: '12px' }}
                    rules={[{ required: location.pathname === CONST_CREATE_GROUPS_AUDIT_TRAIL_PATH_NAME? true : false, message: 'Please select Concept or Group!' }]}
                  >
                    <Select
                      dropdownClassName="search-custom-select"
                      mode="multiple"
                      placeholder="Concept Id/Group"
                      listHeight={170}
                      maxTagCount={1}
                      maxTagTextLength={6}
                      showSearch
                      searchValue={searchConceptKey}
                      getPopupContainer={triggerNode => triggerNode.parentElement}
                      onChange={(value) => handleSelectChange(value, 'nationalIds')}
                      onSearch={value => onDebounceSearchConceptID(value)}
                      notFoundContent={fetchConceptIdsDataPending ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                      filterOption={handleConceptFilter}
                      allowClear
                      onBlur={() => {
                        onDebounceSearchConceptID('');
                        resetConceptIdsData();
                        //defect-4902:The Error message is being displayed even when the Concept id is deleted when both ship-to and concept id is entered
                        updateValidator();
                      }}
                      //defect-4902:The Error message is being displayed even when the Concept id is deleted when both ship-to and concept id is entered
                      onClear={updateValidator}
                      onDeselect={updateValidator}
                    >
                      {conceptData && conceptData.length > 0 && (
                        <Select.Option key={SELECT_ALL_OPTION} value={SELECT_ALL_OPTION}>
                          All Concept Ids
                        </Select.Option>
                      )}
                      {conceptData &&
                        conceptData.map((item, index) => {
                          return (
                            <Select.Option key={`${item}-${index}`} value={item}>
                              {item}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Tooltip>
                <Form.Item name="customerNumber"
                  style={{ display: isGroupsAuditTrail ? 'none': 'inline-flex', width: 'calc(50% - 8px)' }} //COT-9212:BOS2.0 - UI - Audit trail of create groups, groups don't have customer#,so don't show customer# filter
                  rules={[
                    { pattern: /^[0-9]+$/, message: 'Please input numeric.' },
                  ]}
                >
                  {/* merged Melody's code */}
                <Input placeholder="Ship To" onChange={e => handleInputChg(e.target.value, 'customerNumber')} />
                </Form.Item>
              </Form.Item>
            </Col>)}
            {location.pathname === CONST_OGM_SUBS_PATH_NAME && (
              <Col span={5}>
                {/* <Tooltip placement="top" title='Please input 4 char to retrive Concept ID.'> */}
                  <Form.Item name="conceptOrCustomer"
                    validateTrigger={['onChange']}
                    rules={[{
                      validateTrigger: 'onChange',
                      validator: (rule, value, callback) => validateCustomerNumber(rule, value, callback)
                    }]}
                  >
                    <Select
                      dropdownClassName="search-custom-select"
                      placeholder="Concept Id/Group/Ship to"
                      listHeight={170}
                      maxTagCount={1}
                      maxTagTextLength={6}
                      showSearch
                      searchValue={searchConceptKey}
                      getPopupContainer={triggerNode => triggerNode.parentElement}
                      onChange={(value) => handleSelectChange(value, 'conceptOrCustomer')}
                      onSearch={value => onDebounceSearchConceptID(value)}
                      notFoundContent={fetchConceptIdsDataPending ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                      filterOption={handleConceptFilter}
                      allowClear
                      onBlur={() => {
                        onDebounceSearchConceptID('');
                        resetConceptIdsData();
                        //defect-4902:The Error message is being displayed even when the Concept id is deleted when both ship-to and concept id is entered
                        updateValidator();
                      }}
                      //defect-4902:The Error message is being displayed even when the Concept id is deleted when both ship-to and concept id is entered
                      onClear={updateValidator}
                      onDeselect={updateValidator}
                    >
                      {/* {conceptData && conceptData.length > 0 && (
                        <Select.Option key={SELECT_ALL_OPTION} value={SELECT_ALL_OPTION}>
                          All Concept Ids
                        </Select.Option>
                      )} */}
                      {conceptData &&
                        conceptData.map((item, index) => {
                          return (
                            <Select.Option key={`${item}-${index}`} value={item}>
                              {item}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                {/* </Tooltip> */}
              </Col>
            )}

            {location.pathname === CONST_CUSTOMER_GROUP_PATH_NAME && (
              <Col span={4}>
                <Tooltip placement="top" title='Please input 4 char to retrive Group.'>
                  <Form.Item name="groupIds"
                    label="##"
                    validateTrigger={['onChange']}
                    rules={[
                      {
                        validateTrigger: 'onChange',
                        validator: (rule, value, callback) => groupIdsValidator(rule, value, callback)
                      }
                    ]}
                  // onChange={updateValidator}
                  >
                    <Select
                      dropdownClassName="search-custom-select"
                      mode="multiple"
                      placeholder="Group"
                      listHeight={170}
                      maxTagCount={1}
                      maxTagTextLength={6}
                      showSearch
                      searchValue={searchGroupIdKey}
                      getPopupContainer={triggerNode => triggerNode.parentElement}
                      onChange={(value) => handleSelectChange(value, 'groupIds')}
                      onSearch={value => onDebounceSearchGroupID(value)}
                      notFoundContent={fetchGroupIdsDataPending ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                      filterOption={handleGroupIdsFilter}
                      allowClear
                      onBlur={() => {
                        onDebounceSearchGroupID('');
                        resetGroupIdsData();
                        updateValidator();
                      }}
                      onClear={updateValidator}
                      onDeselect={updateValidator}
                    >{groupIdsData && groupIdsData.length > 0 && (
                      <Select.Option key={SELECT_ALL_OPTION} value={SELECT_ALL_OPTION}>
                        All Group Ids
                      </Select.Option>
                    )}
                      {groupIdsData &&
                        groupIdsData.map((item, index) => {
                          return (
                            <Select.Option key={`${item}-${index}`} value={item}>
                              {item}
                            </Select.Option>
                          );
                        })}</Select>
                  </Form.Item>
                </Tooltip>
              </Col>
            )}
            {(location.pathname === CONST_CUSTOMER_GROUP_PATH_NAME || isGroupsAuditTrail) && (
              <>
                <Col span={4}>
                  <Form.Item name="groupDescription"
                    validateTrigger={['onBlur']}
                    rules={[
                      {
                        validateTrigger: 'onBlur',
                        validator: (rule, value, callback) => validateDescription(rule, value, callback)
                      }
                    ]}
                  >
                    <Input placeholder="Group Description" onChange={e => handleInputChg(e.target.value, 'groupDescription')}/>
                  </Form.Item>
                </Col>
              <Col span={4}>
                <Checkbox checked={checkedMyGroups} onChange={(e)=>{setCheckedMyGroups(!checkedMyGroups);handleSelectChange(e.target.checked,'checkedMyGroups')}}>My Groups</Checkbox>
              </Col>
            </>
            )}
            {location.pathname === CONST_SUB_REPOSITORIES_AUDIT_TRAIL_PATH_NAME && 
              <Col span={4}>
                <Form.Item name="originalItem"
                  rules={[
                    { required: true, message: 'Please input original item.' },
                  ]}
                >
                  <Input placeholder="Original Item" onChange={e => handleInputChg(e.target.value, 'originalItem')} />
                </Form.Item>
              </Col>
            }
            {location.pathname === CONST_MAINTAIN_ACCOUNTS_PATH_NAME &&
              <Col span={4}>
                <Form.Item name="accountManagedBy">
                  <Select placeholder="Managed by"
                    mode="multiple"
                    allowClear
                    maxTagCount={1}
                    filterOption={handleManagedByFilter}
                    onChange={(value) => handleSelectChange(value, 'accountManagedBy')}>
                    <Select.Option key={SELECT_ALL_OPTION} value={SELECT_ALL_OPTION}>
                      All
                    </Select.Option>
                    {CONST_MANAGED_BY_OPTIONS.map(item => {
                      return (
                        <Select.Option key={item.key} value={item.key}>
                          {item.label}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            }
            {(location.pathname === CONST_MANAGE_SUBS_PATH_NAME || location.pathname === CONST_OGM_SUBS_PATH_NAME) &&
              <Col {...wrapperCol}>
                <Form.Item name="ruleType">
                  <Select placeholder="All Rules">
                  {RULE_TYPE_OPTIONS.map(item => {
                      return (
                        <Select.Option key={item.key} value={item.key}>
                          {item.label}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            }
            {(location.pathname === CONST_MANAGE_SUBS_PATH_NAME || location.pathname === CONST_MANAGE_OUTS_PATH_NAME || location.pathname === CONST_BOS_ROOT_PATH_NAME) &&
              <Col {...wrapperCol}>
                <Form.Item name="itemCategory">
                  <Select placeholder="Category"
                    mode="multiple"
                    allowClear
                    maxTagCount={1}
                    filterOption={handleCategoryFilter}
                    onChange={(value) => handleSelectChange(value, 'itemCategory')}>
                    <Select.Option key={SELECT_ALL_OPTION} value={SELECT_ALL_OPTION}>
                      All Category
                    </Select.Option>
                    {CONST_CATEGORY_OPTIONS.map(item => {
                      return (
                        <Select.Option key={item.key} value={item.key}>
                          {item.label}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            }
            {(location.pathname === CONST_MANAGE_OUTS_PATH_NAME || location.pathname === CONST_BOS_ROOT_PATH_NAME) &&
              <Col {...wrapperCol}>
                <Form.Item
                  name="deliveryDate"
                  rules={[{ required: location.pathname === CONST_MANAGE_OUTS_PATH_NAME || location.pathname === CONST_BOS_ROOT_PATH_NAME, message: 'Please select Date!' }]}
                >
                  <DatePicker
                    disabledDate={disablePastDt}
                    placeholder="Select Date"
                    format={SLASH_DATE_FORMAT}
                    onChange={(value) =>handleSelectChange(value,'deliveryDate')}
                  />
                </Form.Item>
              </Col>}
            <Col {...wrapperColAction} {...offsetColAction} >
              <Form.Item>
                <Row className='justify-end' style={{ paddingRight: '15px' }}>
                  <Col>
                    <Button htmlType="button" onClick={onReset} className="default-btn">
                      Reset
                    </Button>
                  </Col>
                  <Col offset={1}>
                    <Button htmlType="submit" type="primary" className="primary-btn" id="searchBtn">
                      Search
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
          <>
            {(location.pathname === CONST_MANAGE_OUTS_PATH_NAME || location.pathname === CONST_BOS_ROOT_PATH_NAME) &&
              <Form.Item name="managedBy">
                <div style={{ display: 'inline-block' }}>
                  <Radio.Group onChange={onChange} buttonStyle="solid" value={managedByValue}>
                    <Radio.Button value={CONST_ROLE_OPTIONS_CCC}
                      style={{display: isCorpUser() ? '' : 'none'}}>CCC</Radio.Button>
                    <Radio.Button value={CONST_ROLE_OPTIONS_CST}
                      style={{display: isCorpUser() ? '' : 'none'}}>CST</Radio.Button >
                    <Radio.Button value={CONST_ROLE_OPTIONS_LOCAL}>LOCAL</Radio.Button >
                  </Radio.Group>
                </div>
              </Form.Item>
            }
          </>
        </Form>
      </Spin>
    </div>
  );
}
