import React from 'react';
import { CONST_REPORT_TABS_NEW_COLUMNS } from '../../common/reportsConstants';
import CommonPage from './CommonPage';
// import PropTypes from 'prop-types';

export default function DailySalesSite() {
  const tabKey = 'Summary';
  const columnsProp = CONST_REPORT_TABS_NEW_COLUMNS.filter(item => item.key === tabKey)[0].salesSite.columns;
  const level = CONST_REPORT_TABS_NEW_COLUMNS.filter(item => item.key === tabKey)[0].salesSite.level;
  return (
    <div className="reports-new-daily-sales-site">
     <CommonPage tabKey={tabKey} columnsProp={columnsProp} level={level} />
    </div>
  );
};

DailySalesSite.propTypes = {};
DailySalesSite.defaultProps = {};
