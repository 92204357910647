export const REPORTS_FETCH_SC_IN_ACTION_DATA_BEGIN = 'REPORTS_FETCH_SC_IN_ACTION_DATA_BEGIN';
export const REPORTS_FETCH_SC_IN_ACTION_DATA_SUCCESS = 'REPORTS_FETCH_SC_IN_ACTION_DATA_SUCCESS';
export const REPORTS_FETCH_SC_IN_ACTION_DATA_FAILURE = 'REPORTS_FETCH_SC_IN_ACTION_DATA_FAILURE';
export const REPORTS_FETCH_SC_IN_ACTION_DATA_DISMISS_ERROR = 'REPORTS_FETCH_SC_IN_ACTION_DATA_DISMISS_ERROR';
export const REPORTS_RESET_REPORTS_CUSTOMER_LEVEL_DATA = 'REPORTS_RESET_REPORTS_CUSTOMER_LEVEL_DATA';
export const REPORTS_RESET_REPORTS_ORDER_LEVEL_DATA = 'REPORTS_RESET_REPORTS_ORDER_LEVEL_DATA';
export const REPORTS_RESET_REPORTS_DATA = 'REPORTS_RESET_REPORTS_DATA';
export const REPORTS_EXPORT_SC_IN_ACTION_REPORT_DATA_BEGIN = 'REPORTS_EXPORT_SC_IN_ACTION_REPORT_DATA_BEGIN';
export const REPORTS_EXPORT_SC_IN_ACTION_REPORT_DATA_SUCCESS = 'REPORTS_EXPORT_SC_IN_ACTION_REPORT_DATA_SUCCESS';
export const REPORTS_EXPORT_SC_IN_ACTION_REPORT_DATA_FAILURE = 'REPORTS_EXPORT_SC_IN_ACTION_REPORT_DATA_FAILURE';
export const REPORTS_EXPORT_SC_IN_ACTION_REPORT_DATA_DISMISS_ERROR = 'REPORTS_EXPORT_SC_IN_ACTION_REPORT_DATA_DISMISS_ERROR';
export const REPORTS_FETCH_SC_IN_ACTION_CUSTOMER_DATA_BEGIN = 'REPORTS_FETCH_SC_IN_ACTION_CUSTOMER_DATA_BEGIN';
export const REPORTS_FETCH_SC_IN_ACTION_CUSTOMER_DATA_SUCCESS = 'REPORTS_FETCH_SC_IN_ACTION_CUSTOMER_DATA_SUCCESS';
export const REPORTS_FETCH_SC_IN_ACTION_CUSTOMER_DATA_FAILURE = 'REPORTS_FETCH_SC_IN_ACTION_CUSTOMER_DATA_FAILURE';
export const REPORTS_FETCH_SC_IN_ACTION_CUSTOMER_DATA_DISMISS_ERROR = 'REPORTS_FETCH_SC_IN_ACTION_CUSTOMER_DATA_DISMISS_ERROR';
export const REPORTS_FETCH_SC_IN_ACTION_ORDER_DATA_BEGIN = 'REPORTS_FETCH_SC_IN_ACTION_ORDER_DATA_BEGIN';
export const REPORTS_FETCH_SC_IN_ACTION_ORDER_DATA_SUCCESS = 'REPORTS_FETCH_SC_IN_ACTION_ORDER_DATA_SUCCESS';
export const REPORTS_FETCH_SC_IN_ACTION_ORDER_DATA_FAILURE = 'REPORTS_FETCH_SC_IN_ACTION_ORDER_DATA_FAILURE';
export const REPORTS_FETCH_SC_IN_ACTION_ORDER_DATA_DISMISS_ERROR = 'REPORTS_FETCH_SC_IN_ACTION_ORDER_DATA_DISMISS_ERROR';
export const REPORTS_EXPORT_SC_IN_ACTION_CUSTOMER_REPORT_BEGIN = 'REPORTS_EXPORT_SC_IN_ACTION_CUSTOMER_REPORT_BEGIN';
export const REPORTS_EXPORT_SC_IN_ACTION_CUSTOMER_REPORT_SUCCESS = 'REPORTS_EXPORT_SC_IN_ACTION_CUSTOMER_REPORT_SUCCESS';
export const REPORTS_EXPORT_SC_IN_ACTION_CUSTOMER_REPORT_FAILURE = 'REPORTS_EXPORT_SC_IN_ACTION_CUSTOMER_REPORT_FAILURE';
export const REPORTS_EXPORT_SC_IN_ACTION_CUSTOMER_REPORT_DISMISS_ERROR = 'REPORTS_EXPORT_SC_IN_ACTION_CUSTOMER_REPORT_DISMISS_ERROR';
export const REPORTS_EXPORT_SC_IN_ACTION_ORDER_REPORT_BEGIN = 'REPORTS_EXPORT_SC_IN_ACTION_ORDER_REPORT_BEGIN';
export const REPORTS_EXPORT_SC_IN_ACTION_ORDER_REPORT_SUCCESS = 'REPORTS_EXPORT_SC_IN_ACTION_ORDER_REPORT_SUCCESS';
export const REPORTS_EXPORT_SC_IN_ACTION_ORDER_REPORT_FAILURE = 'REPORTS_EXPORT_SC_IN_ACTION_ORDER_REPORT_FAILURE';
export const REPORTS_EXPORT_SC_IN_ACTION_ORDER_REPORT_DISMISS_ERROR = 'REPORTS_EXPORT_SC_IN_ACTION_ORDER_REPORT_DISMISS_ERROR';
export const REPORTS_FETCH_SUMMARY_DATA_BEGIN = 'REPORTS_FETCH_SUMMARY_DATA_BEGIN';
export const REPORTS_FETCH_SUMMARY_DATA_SUCCESS = 'REPORTS_FETCH_SUMMARY_DATA_SUCCESS';
export const REPORTS_FETCH_SUMMARY_DATA_FAILURE = 'REPORTS_FETCH_SUMMARY_DATA_FAILURE';
export const REPORTS_FETCH_SUMMARY_DATA_DISMISS_ERROR = 'REPORTS_FETCH_SUMMARY_DATA_DISMISS_ERROR';
export const REPORTS_EXPORT_SUMMARY_REPORT_BEGIN = 'REPORTS_EXPORT_SUMMARY_REPORT_BEGIN';
export const REPORTS_EXPORT_SUMMARY_REPORT_SUCCESS = 'REPORTS_EXPORT_SUMMARY_REPORT_SUCCESS';
export const REPORTS_EXPORT_SUMMARY_REPORT_FAILURE = 'REPORTS_EXPORT_SUMMARY_REPORT_FAILURE';
export const REPORTS_EXPORT_SUMMARY_REPORT_DISMISS_ERROR = 'REPORTS_EXPORT_SUMMARY_REPORT_DISMISS_ERROR';
export const REPORTS_FETCH_SUMMARY_CUSTOMER_DATA_BEGIN = 'REPORTS_FETCH_SUMMARY_CUSTOMER_DATA_BEGIN';
export const REPORTS_FETCH_SUMMARY_CUSTOMER_DATA_SUCCESS = 'REPORTS_FETCH_SUMMARY_CUSTOMER_DATA_SUCCESS';
export const REPORTS_FETCH_SUMMARY_CUSTOMER_DATA_FAILURE = 'REPORTS_FETCH_SUMMARY_CUSTOMER_DATA_FAILURE';
export const REPORTS_FETCH_SUMMARY_CUSTOMER_DATA_DISMISS_ERROR = 'REPORTS_FETCH_SUMMARY_CUSTOMER_DATA_DISMISS_ERROR';
export const REPORTS_FETCH_SUMMARY_ORDER_DATA_BEGIN = 'REPORTS_FETCH_SUMMARY_ORDER_DATA_BEGIN';
export const REPORTS_FETCH_SUMMARY_ORDER_DATA_SUCCESS = 'REPORTS_FETCH_SUMMARY_ORDER_DATA_SUCCESS';
export const REPORTS_FETCH_SUMMARY_ORDER_DATA_FAILURE = 'REPORTS_FETCH_SUMMARY_ORDER_DATA_FAILURE';
export const REPORTS_FETCH_SUMMARY_ORDER_DATA_DISMISS_ERROR = 'REPORTS_FETCH_SUMMARY_ORDER_DATA_DISMISS_ERROR';
export const REPORTS_EXPORT_SUMMARY_CUSTOMER_REPORT_BEGIN = 'REPORTS_EXPORT_SUMMARY_CUSTOMER_REPORT_BEGIN';
export const REPORTS_EXPORT_SUMMARY_CUSTOMER_REPORT_SUCCESS = 'REPORTS_EXPORT_SUMMARY_CUSTOMER_REPORT_SUCCESS';
export const REPORTS_EXPORT_SUMMARY_CUSTOMER_REPORT_FAILURE = 'REPORTS_EXPORT_SUMMARY_CUSTOMER_REPORT_FAILURE';
export const REPORTS_EXPORT_SUMMARY_CUSTOMER_REPORT_DISMISS_ERROR = 'REPORTS_EXPORT_SUMMARY_CUSTOMER_REPORT_DISMISS_ERROR';
export const REPORTS_EXPORT_SUMMARY_ORDER_REPORT_BEGIN = 'REPORTS_EXPORT_SUMMARY_ORDER_REPORT_BEGIN';
export const REPORTS_EXPORT_SUMMARY_ORDER_REPORT_SUCCESS = 'REPORTS_EXPORT_SUMMARY_ORDER_REPORT_SUCCESS';
export const REPORTS_EXPORT_SUMMARY_ORDER_REPORT_FAILURE = 'REPORTS_EXPORT_SUMMARY_ORDER_REPORT_FAILURE';
export const REPORTS_EXPORT_SUMMARY_ORDER_REPORT_DISMISS_ERROR = 'REPORTS_EXPORT_SUMMARY_ORDER_REPORT_DISMISS_ERROR';
export const REPORTS_FETCH_ORDERS_DATA_BEGIN = 'REPORTS_FETCH_ORDERS_DATA_BEGIN';
export const REPORTS_FETCH_ORDERS_DATA_SUCCESS = 'REPORTS_FETCH_ORDERS_DATA_SUCCESS';
export const REPORTS_FETCH_ORDERS_DATA_FAILURE = 'REPORTS_FETCH_ORDERS_DATA_FAILURE';
export const REPORTS_FETCH_ORDERS_DATA_DISMISS_ERROR = 'REPORTS_FETCH_ORDERS_DATA_DISMISS_ERROR';
export const REPORTS_EXPORT_ORDERS_REPORT_BEGIN = 'REPORTS_EXPORT_ORDERS_REPORT_BEGIN';
export const REPORTS_EXPORT_ORDERS_REPORT_SUCCESS = 'REPORTS_EXPORT_ORDERS_REPORT_SUCCESS';
export const REPORTS_EXPORT_ORDERS_REPORT_FAILURE = 'REPORTS_EXPORT_ORDERS_REPORT_FAILURE';
export const REPORTS_EXPORT_ORDERS_REPORT_DISMISS_ERROR = 'REPORTS_EXPORT_ORDERS_REPORT_DISMISS_ERROR';
export const REPORTS_FETCH_ORDERS_REPORT_BY_CHANNEL_TYPE_BEGIN = 'REPORTS_FETCH_ORDERS_REPORT_BY_CHANNEL_TYPE_BEGIN';
export const REPORTS_FETCH_ORDERS_REPORT_BY_CHANNEL_TYPE_SUCCESS = 'REPORTS_FETCH_ORDERS_REPORT_BY_CHANNEL_TYPE_SUCCESS';
export const REPORTS_FETCH_ORDERS_REPORT_BY_CHANNEL_TYPE_FAILURE = 'REPORTS_FETCH_ORDERS_REPORT_BY_CHANNEL_TYPE_FAILURE';
export const REPORTS_FETCH_ORDERS_REPORT_BY_CHANNEL_TYPE_DISMISS_ERROR = 'REPORTS_FETCH_ORDERS_REPORT_BY_CHANNEL_TYPE_DISMISS_ERROR';
export const REPORTS_FETCH_SUMMARY_DATA_FROM_CHANNEL_TYPE_BEGIN = 'REPORTS_FETCH_SUMMARY_DATA_FROM_CHANNEL_TYPE_BEGIN';
export const REPORTS_FETCH_SUMMARY_DATA_FROM_CHANNEL_TYPE_SUCCESS = 'REPORTS_FETCH_SUMMARY_DATA_FROM_CHANNEL_TYPE_SUCCESS';
export const REPORTS_FETCH_SUMMARY_DATA_FROM_CHANNEL_TYPE_FAILURE = 'REPORTS_FETCH_SUMMARY_DATA_FROM_CHANNEL_TYPE_FAILURE';
export const REPORTS_FETCH_SUMMARY_DATA_FROM_CHANNEL_TYPE_DISMISS_ERROR = 'REPORTS_FETCH_SUMMARY_DATA_FROM_CHANNEL_TYPE_DISMISS_ERROR';
export const REPORTS_RESET_REPORTS_DATA_ACCOUNT = 'REPORTS_RESET_REPORTS_DATA_ACCOUNT';
export const REPORTS_RESET_REPORTS_DATA_ORDERS = 'REPORTS_RESET_REPORTS_DATA_ORDERS';
export const REPORTS_EXPORT_ORDERS_REPORT_BY_CHANNEL_TYPE_BEGIN = 'REPORTS_EXPORT_ORDERS_REPORT_BY_CHANNEL_TYPE_BEGIN';
export const REPORTS_EXPORT_ORDERS_REPORT_BY_CHANNEL_TYPE_SUCCESS = 'REPORTS_EXPORT_ORDERS_REPORT_BY_CHANNEL_TYPE_SUCCESS';
export const REPORTS_EXPORT_ORDERS_REPORT_BY_CHANNEL_TYPE_FAILURE = 'REPORTS_EXPORT_ORDERS_REPORT_BY_CHANNEL_TYPE_FAILURE';
export const REPORTS_EXPORT_ORDERS_REPORT_BY_CHANNEL_TYPE_DISMISS_ERROR = 'REPORTS_EXPORT_ORDERS_REPORT_BY_CHANNEL_TYPE_DISMISS_ERROR';
export const REPORTS_FETCH_ORDER_DETAIL_DATA_BEGIN = 'REPORTS_FETCH_ORDER_DETAIL_DATA_BEGIN';
export const REPORTS_FETCH_ORDER_DETAIL_DATA_SUCCESS = 'REPORTS_FETCH_ORDER_DETAIL_DATA_SUCCESS';
export const REPORTS_FETCH_ORDER_DETAIL_DATA_FAILURE = 'REPORTS_FETCH_ORDER_DETAIL_DATA_FAILURE';
export const REPORTS_FETCH_ORDER_DETAIL_DATA_DISMISS_ERROR = 'REPORTS_FETCH_ORDER_DETAIL_DATA_DISMISS_ERROR';
export const REPORTS_FETCH_ORDER_DETAIL_REPORT_BEGIN = 'REPORTS_FETCH_ORDER_DETAIL_REPORT_BEGIN';
export const REPORTS_FETCH_ORDER_DETAIL_REPORT_SUCCESS = 'REPORTS_FETCH_ORDER_DETAIL_REPORT_SUCCESS';
export const REPORTS_FETCH_ORDER_DETAIL_REPORT_FAILURE = 'REPORTS_FETCH_ORDER_DETAIL_REPORT_FAILURE';
export const REPORTS_FETCH_ORDER_DETAIL_REPORT_DISMISS_ERROR = 'REPORTS_FETCH_ORDER_DETAIL_REPORT_DISMISS_ERROR';
export const REPORTS_RESET_REPORTS_ORDER_DETAIL_LEVEL_DATA = 'REPORTS_RESET_REPORTS_ORDER_DETAIL_LEVEL_DATA';
export const REPORTS_EXPORT_ORDER_DETAIL_REPORT_BEGIN = 'REPORTS_EXPORT_ORDER_DETAIL_REPORT_BEGIN';
export const REPORTS_EXPORT_ORDER_DETAIL_REPORT_SUCCESS = 'REPORTS_EXPORT_ORDER_DETAIL_REPORT_SUCCESS';
export const REPORTS_EXPORT_ORDER_DETAIL_REPORT_FAILURE = 'REPORTS_EXPORT_ORDER_DETAIL_REPORT_FAILURE';
export const REPORTS_EXPORT_ORDER_DETAIL_REPORT_DISMISS_ERROR = 'REPORTS_EXPORT_ORDER_DETAIL_REPORT_DISMISS_ERROR';
export const REPORTS_FETCH_SUMMARY_DATA_BY_REGION_BEGIN = 'REPORTS_FETCH_SUMMARY_DATA_BY_REGION_BEGIN';
export const REPORTS_FETCH_SUMMARY_DATA_BY_REGION_SUCCESS = 'REPORTS_FETCH_SUMMARY_DATA_BY_REGION_SUCCESS';
export const REPORTS_FETCH_SUMMARY_DATA_BY_REGION_FAILURE = 'REPORTS_FETCH_SUMMARY_DATA_BY_REGION_FAILURE';
export const REPORTS_FETCH_SUMMARY_DATA_BY_REGION_DISMISS_ERROR = 'REPORTS_FETCH_SUMMARY_DATA_BY_REGION_DISMISS_ERROR';
export const REPORTS_EXPORT_SUMMARY_REGION_REPORT_BEGIN = 'REPORTS_EXPORT_SUMMARY_REGION_REPORT_BEGIN';
export const REPORTS_EXPORT_SUMMARY_REGION_REPORT_SUCCESS = 'REPORTS_EXPORT_SUMMARY_REGION_REPORT_SUCCESS';
export const REPORTS_EXPORT_SUMMARY_REGION_REPORT_FAILURE = 'REPORTS_EXPORT_SUMMARY_REGION_REPORT_FAILURE';
export const REPORTS_EXPORT_SUMMARY_REGION_REPORT_DISMISS_ERROR = 'REPORTS_EXPORT_SUMMARY_REGION_REPORT_DISMISS_ERROR';
export const REPORTS_FETCH_OOS_CATEGORY_REPORT_BEGIN = 'REPORTS_FETCH_OOS_CATEGORY_REPORT_BEGIN';
export const REPORTS_FETCH_OOS_CATEGORY_REPORT_SUCCESS = 'REPORTS_FETCH_OOS_CATEGORY_REPORT_SUCCESS';
export const REPORTS_FETCH_OOS_CATEGORY_REPORT_FAILURE = 'REPORTS_FETCH_OOS_CATEGORY_REPORT_FAILURE';
export const REPORTS_FETCH_OOS_CATEGORY_REPORT_DISMISS_ERROR = 'REPORTS_FETCH_OOS_CATEGORY_REPORT_DISMISS_ERROR';
export const REPORTS_FETCH_OOS_CUSTOMER_REPORT_BEGIN = 'REPORTS_FETCH_OOS_CUSTOMER_REPORT_BEGIN';
export const REPORTS_FETCH_OOS_CUSTOMER_REPORT_SUCCESS = 'REPORTS_FETCH_OOS_CUSTOMER_REPORT_SUCCESS';
export const REPORTS_FETCH_OOS_CUSTOMER_REPORT_FAILURE = 'REPORTS_FETCH_OOS_CUSTOMER_REPORT_FAILURE';
export const REPORTS_FETCH_OOS_CUSTOMER_REPORT_DISMISS_ERROR = 'REPORTS_FETCH_OOS_CUSTOMER_REPORT_DISMISS_ERROR';
export const REPORTS_FETCH_OOS_ITEM_REPORT_BEGIN = 'REPORTS_FETCH_OOS_ITEM_REPORT_BEGIN';
export const REPORTS_FETCH_OOS_ITEM_REPORT_SUCCESS = 'REPORTS_FETCH_OOS_ITEM_REPORT_SUCCESS';
export const REPORTS_FETCH_OOS_ITEM_REPORT_FAILURE = 'REPORTS_FETCH_OOS_ITEM_REPORT_FAILURE';
export const REPORTS_FETCH_OOS_ITEM_REPORT_DISMISS_ERROR = 'REPORTS_FETCH_OOS_ITEM_REPORT_DISMISS_ERROR';
export const REPORTS_EXPORT_OOS_CUSTOMER_REPORT_BEGIN = 'REPORTS_EXPORT_OOS_CUSTOMER_REPORT_BEGIN';
export const REPORTS_EXPORT_OOS_CUSTOMER_REPORT_SUCCESS = 'REPORTS_EXPORT_OOS_CUSTOMER_REPORT_SUCCESS';
export const REPORTS_EXPORT_OOS_CUSTOMER_REPORT_FAILURE = 'REPORTS_EXPORT_OOS_CUSTOMER_REPORT_FAILURE';
export const REPORTS_EXPORT_OOS_CUSTOMER_REPORT_DISMISS_ERROR = 'REPORTS_EXPORT_OOS_CUSTOMER_REPORT_DISMISS_ERROR';
export const REPORTS_EXPORT_OOS_ITEM_REPORT_BEGIN = 'REPORTS_EXPORT_OOS_ITEM_REPORT_BEGIN';
export const REPORTS_EXPORT_OOS_ITEM_REPORT_SUCCESS = 'REPORTS_EXPORT_OOS_ITEM_REPORT_SUCCESS';
export const REPORTS_EXPORT_OOS_ITEM_REPORT_FAILURE = 'REPORTS_EXPORT_OOS_ITEM_REPORT_FAILURE';
export const REPORTS_EXPORT_OOS_ITEM_REPORT_DISMISS_ERROR = 'REPORTS_EXPORT_OOS_ITEM_REPORT_DISMISS_ERROR';
export const REPORTS_EXPORT_OOS_CATEGORY_REPORT_BEGIN = 'REPORTS_EXPORT_OOS_CATEGORY_REPORT_BEGIN';
export const REPORTS_EXPORT_OOS_CATEGORY_REPORT_SUCCESS = 'REPORTS_EXPORT_OOS_CATEGORY_REPORT_SUCCESS';
export const REPORTS_EXPORT_OOS_CATEGORY_REPORT_FAILURE = 'REPORTS_EXPORT_OOS_CATEGORY_REPORT_FAILURE';
export const REPORTS_EXPORT_OOS_CATEGORY_REPORT_DISMISS_ERROR = 'REPORTS_EXPORT_OOS_CATEGORY_REPORT_DISMISS_ERROR';
export const REPORTS_FETCH_OOS_REPORT_BY_SITE_BEGIN = 'REPORTS_FETCH_OOS_REPORT_BY_SITE_BEGIN';
export const REPORTS_FETCH_OOS_REPORT_BY_SITE_SUCCESS = 'REPORTS_FETCH_OOS_REPORT_BY_SITE_SUCCESS';
export const REPORTS_FETCH_OOS_REPORT_BY_SITE_FAILURE = 'REPORTS_FETCH_OOS_REPORT_BY_SITE_FAILURE';
export const REPORTS_FETCH_OOS_REPORT_BY_SITE_DISMISS_ERROR = 'REPORTS_FETCH_OOS_REPORT_BY_SITE_DISMISS_ERROR';
export const REPORTS_FETCH_OOS_REPORT_BY_CATEGORY_BEGIN = 'REPORTS_FETCH_OOS_REPORT_BY_CATEGORY_BEGIN';
export const REPORTS_FETCH_OOS_REPORT_BY_CATEGORY_SUCCESS = 'REPORTS_FETCH_OOS_REPORT_BY_CATEGORY_SUCCESS';
export const REPORTS_FETCH_OOS_REPORT_BY_CATEGORY_FAILURE = 'REPORTS_FETCH_OOS_REPORT_BY_CATEGORY_FAILURE';
export const REPORTS_FETCH_OOS_REPORT_BY_CATEGORY_DISMISS_ERROR = 'REPORTS_FETCH_OOS_REPORT_BY_CATEGORY_DISMISS_ERROR';
export const REPORTS_FETCH_OOS_REPORT_BY_ORDER_NUMBER_BEGIN = 'REPORTS_FETCH_OOS_REPORT_BY_ORDER_NUMBER_BEGIN';
export const REPORTS_FETCH_OOS_REPORT_BY_ORDER_NUMBER_SUCCESS = 'REPORTS_FETCH_OOS_REPORT_BY_ORDER_NUMBER_SUCCESS';
export const REPORTS_FETCH_OOS_REPORT_BY_ORDER_NUMBER_FAILURE = 'REPORTS_FETCH_OOS_REPORT_BY_ORDER_NUMBER_FAILURE';
export const REPORTS_FETCH_OOS_REPORT_BY_ORDER_NUMBER_DISMISS_ERROR = 'REPORTS_FETCH_OOS_REPORT_BY_ORDER_NUMBER_DISMISS_ERROR';
export const REPORTS_EXPORT_OOS_REPORT_BY_SITE_BEGIN = 'REPORTS_EXPORT_OOS_REPORT_BY_SITE_BEGIN';
export const REPORTS_EXPORT_OOS_REPORT_BY_SITE_SUCCESS = 'REPORTS_EXPORT_OOS_REPORT_BY_SITE_SUCCESS';
export const REPORTS_EXPORT_OOS_REPORT_BY_SITE_FAILURE = 'REPORTS_EXPORT_OOS_REPORT_BY_SITE_FAILURE';
export const REPORTS_EXPORT_OOS_REPORT_BY_SITE_DISMISS_ERROR = 'REPORTS_EXPORT_OOS_REPORT_BY_SITE_DISMISS_ERROR';
export const REPORTS_EXPORT_OOS_REPORT_BY_CATEGORY_BEGIN = 'REPORTS_EXPORT_OOS_REPORT_BY_CATEGORY_BEGIN';
export const REPORTS_EXPORT_OOS_REPORT_BY_CATEGORY_SUCCESS = 'REPORTS_EXPORT_OOS_REPORT_BY_CATEGORY_SUCCESS';
export const REPORTS_EXPORT_OOS_REPORT_BY_CATEGORY_FAILURE = 'REPORTS_EXPORT_OOS_REPORT_BY_CATEGORY_FAILURE';
export const REPORTS_EXPORT_OOS_REPORT_BY_CATEGORY_DISMISS_ERROR = 'REPORTS_EXPORT_OOS_REPORT_BY_CATEGORY_DISMISS_ERROR';
export const REPORTS_EXPORT_OOS_REPORT_BY_ORDER_NUMBER_BEGIN = 'REPORTS_EXPORT_OOS_REPORT_BY_ORDER_NUMBER_BEGIN';
export const REPORTS_EXPORT_OOS_REPORT_BY_ORDER_NUMBER_SUCCESS = 'REPORTS_EXPORT_OOS_REPORT_BY_ORDER_NUMBER_SUCCESS';
export const REPORTS_EXPORT_OOS_REPORT_BY_ORDER_NUMBER_FAILURE = 'REPORTS_EXPORT_OOS_REPORT_BY_ORDER_NUMBER_FAILURE';
export const REPORTS_EXPORT_OOS_REPORT_BY_ORDER_NUMBER_DISMISS_ERROR = 'REPORTS_EXPORT_OOS_REPORT_BY_ORDER_NUMBER_DISMISS_ERROR';
export const REPORTS_FETCH_OOS_ACCOUNT_ACCOUNT_TYPE_BEGIN = 'REPORTS_FETCH_OOS_ACCOUNT_ACCOUNT_TYPE_BEGIN';
export const REPORTS_FETCH_OOS_ACCOUNT_ACCOUNT_TYPE_SUCCESS = 'REPORTS_FETCH_OOS_ACCOUNT_ACCOUNT_TYPE_SUCCESS';
export const REPORTS_FETCH_OOS_ACCOUNT_ACCOUNT_TYPE_FAILURE = 'REPORTS_FETCH_OOS_ACCOUNT_ACCOUNT_TYPE_FAILURE';
export const REPORTS_FETCH_OOS_ACCOUNT_ACCOUNT_TYPE_DISMISS_ERROR = 'REPORTS_FETCH_OOS_ACCOUNT_ACCOUNT_TYPE_DISMISS_ERROR';
export const REPORTS_FETCH_OOS_ACCOUNT_CUSTOMER_BEGIN = 'REPORTS_FETCH_OOS_ACCOUNT_CUSTOMER_BEGIN';
export const REPORTS_FETCH_OOS_ACCOUNT_CUSTOMER_SUCCESS = 'REPORTS_FETCH_OOS_ACCOUNT_CUSTOMER_SUCCESS';
export const REPORTS_FETCH_OOS_ACCOUNT_CUSTOMER_FAILURE = 'REPORTS_FETCH_OOS_ACCOUNT_CUSTOMER_FAILURE';
export const REPORTS_FETCH_OOS_ACCOUNT_CUSTOMER_DISMISS_ERROR = 'REPORTS_FETCH_OOS_ACCOUNT_CUSTOMER_DISMISS_ERROR';
export const REPORTS_EXPORT_OOS_ACCOUNT_ACCOUNT_TYPE_BEGIN = 'REPORTS_EXPORT_OOS_ACCOUNT_ACCOUNT_TYPE_BEGIN';
export const REPORTS_EXPORT_OOS_ACCOUNT_ACCOUNT_TYPE_SUCCESS = 'REPORTS_EXPORT_OOS_ACCOUNT_ACCOUNT_TYPE_SUCCESS';
export const REPORTS_EXPORT_OOS_ACCOUNT_ACCOUNT_TYPE_FAILURE = 'REPORTS_EXPORT_OOS_ACCOUNT_ACCOUNT_TYPE_FAILURE';
export const REPORTS_EXPORT_OOS_ACCOUNT_ACCOUNT_TYPE_DISMISS_ERROR = 'REPORTS_EXPORT_OOS_ACCOUNT_ACCOUNT_TYPE_DISMISS_ERROR';
export const REPORTS_EXPORT_OOS_ACCOUNT_CUSTOMER_BEGIN = 'REPORTS_EXPORT_OOS_ACCOUNT_CUSTOMER_BEGIN';
export const REPORTS_EXPORT_OOS_ACCOUNT_CUSTOMER_SUCCESS = 'REPORTS_EXPORT_OOS_ACCOUNT_CUSTOMER_SUCCESS';
export const REPORTS_EXPORT_OOS_ACCOUNT_CUSTOMER_FAILURE = 'REPORTS_EXPORT_OOS_ACCOUNT_CUSTOMER_FAILURE';
export const REPORTS_EXPORT_OOS_ACCOUNT_CUSTOMER_DISMISS_ERROR = 'REPORTS_EXPORT_OOS_ACCOUNT_CUSTOMER_DISMISS_ERROR';
export const REPORTS_FETCH_OOS_ACCOUNT_ORDER_BEGIN = 'REPORTS_FETCH_OOS_ACCOUNT_ORDER_BEGIN';
export const REPORTS_FETCH_OOS_ACCOUNT_ORDER_SUCCESS = 'REPORTS_FETCH_OOS_ACCOUNT_ORDER_SUCCESS';
export const REPORTS_FETCH_OOS_ACCOUNT_ORDER_FAILURE = 'REPORTS_FETCH_OOS_ACCOUNT_ORDER_FAILURE';
export const REPORTS_FETCH_OOS_ACCOUNT_ORDER_DISMISS_ERROR = 'REPORTS_FETCH_OOS_ACCOUNT_ORDER_DISMISS_ERROR';
export const REPORTS_EXPORT_OOS_ACCOUNT_ORDER_BEGIN = 'REPORTS_EXPORT_OOS_ACCOUNT_ORDER_BEGIN';
export const REPORTS_EXPORT_OOS_ACCOUNT_ORDER_SUCCESS = 'REPORTS_EXPORT_OOS_ACCOUNT_ORDER_SUCCESS';
export const REPORTS_EXPORT_OOS_ACCOUNT_ORDER_FAILURE = 'REPORTS_EXPORT_OOS_ACCOUNT_ORDER_FAILURE';
export const REPORTS_EXPORT_OOS_ACCOUNT_ORDER_DISMISS_ERROR = 'REPORTS_EXPORT_OOS_ACCOUNT_ORDER_DISMISS_ERROR';
export const REPORTS_FETCH_SUMMARY_DATA_BY_SITE_BEGIN = 'REPORTS_FETCH_SUMMARY_DATA_BY_SITE_BEGIN';
export const REPORTS_FETCH_SUMMARY_DATA_BY_SITE_SUCCESS = 'REPORTS_FETCH_SUMMARY_DATA_BY_SITE_SUCCESS';
export const REPORTS_FETCH_SUMMARY_DATA_BY_SITE_FAILURE = 'REPORTS_FETCH_SUMMARY_DATA_BY_SITE_FAILURE';
export const REPORTS_FETCH_SUMMARY_DATA_BY_SITE_DISMISS_ERROR = 'REPORTS_FETCH_SUMMARY_DATA_BY_SITE_DISMISS_ERROR';
export const REPORTS_EXPORT_SUMMARY_DATA_BY_SITE_BEGIN = 'REPORTS_EXPORT_SUMMARY_DATA_BY_SITE_BEGIN';
export const REPORTS_EXPORT_SUMMARY_DATA_BY_SITE_SUCCESS = 'REPORTS_EXPORT_SUMMARY_DATA_BY_SITE_SUCCESS';
export const REPORTS_EXPORT_SUMMARY_DATA_BY_SITE_FAILURE = 'REPORTS_EXPORT_SUMMARY_DATA_BY_SITE_FAILURE';
export const REPORTS_EXPORT_SUMMARY_DATA_BY_SITE_DISMISS_ERROR = 'REPORTS_EXPORT_SUMMARY_DATA_BY_SITE_DISMISS_ERROR';
export const REPORTS_RESET_SUMMARY_DATA_BY_SITE = 'REPORTS_RESET_SUMMARY_DATA_BY_SITE';
