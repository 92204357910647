export const SELECT_ALL_OPTION = 'All';
export const AUTH_ERROR_MSG = 'Authorization required.';
export const DASH_DATE_FORMAT = 'YYYY-MM-DD';
export const SLASH_DATE_FORMAT = 'MM/DD/YYYY';
export const COMMON_DATE_FORMAT = 'YYYYMMDD';
export const DATE_TIME_ONE_FORMAT = 'MM/DD/YYYY hh:mm A';
export const DATE_TIME_TWO_FORMAT = 'MM/DD/YYYY HH:mm:ss';
export const DATE_TIME_THREE_FORMAT = 'YYYYMMDD HH:mm:ss';
export const TIME_HOUR_MINUES = "HH:mm";

export const CONST_PAGINATION = {
  pageIndex: 1,
  pageSize: 5,
  total: 0,
  pageSizeOptions: [5, 20, 50, 100, 200, 500, 1000],
};

export const CONST_PAGINATION_TEN = {
  pageIndex: 1,
  pageSize: 10,
  total: 0,
  pageSizeOptions: [10, 20, 50, 100, 200, 500, 1000],
};

export const DEFAULT_REPORT_MODAL_KEY_VALUE = {
  scId: null,
  customerNumber: null,
  customerName: null,
  accountType: null,
  offDaySales: null,
  opco: null,
  orderNo: null,
  status: null,
  tabKey:'',
};
export const REPORTS_HEADER_TITLE_SUMMARY="dailySalesSummary";
export const USER_GUIDE_LINK = 'https://sysco.sharepoint.com/sites/OmnichannelResources/Shared%20Documents/Forms/AllItems.aspx?newTargetListUrl=%2Fsites%2FOmnichannelResources%2FShared%20Documents&viewpath=%2Fsites%2FOmnichannelResources%2FShared%20Documents%2FForms%2FAllItems%2Easpx&id=%2Fsites%2FOmnichannelResources%2FShared%20Documents%2FSysco%20In%20Action%20%2B%20%20%28SIA%2B%29&viewid=e3fe4e9e%2D0246%2D48d5%2Dae98%2D95d832a929ca&OR=Teams%2DHL&CT=1687741997648';
export const ACCOUNT_TYPES = [
   'CMU', 'LCC','TRS','TRP','OTH','VND','NON',
]
export const AUDIT_TRAIL_EXPORT_LIMIT = 10000;
export const AUDIT_TRAIL_EXPORT_LIMIT_MSG = `The maximum number of rules to be exported exceeds the ${AUDIT_TRAIL_EXPORT_LIMIT} record limit. Please include additional filter criteria to refine the number of records.`;
export const BOS_SEARCH_CONDITION_KEY = "BOS_SEARCH_CONDITION";
export const SIA_TERRITORY_DATA_KEY = "SIA_TERRITORY_DATA";
export const SIA_ORG_DROPDOWN_LIST_KEY = "SIA_ORG_DROPDOWN_LIST";
export const SIA_DASHBOARD_CONDITION_DATA_KEY = "SIA_DASHBOARD_CONDITION_DATA";
export const SIA_REPORTS_KEY_VALUE = "SIA_REPORTS_KEY_VALUE";
export const SIA_TEST_USER_ID_KEY = 'SIA_TEST_USER_ID';
export const BOS_TERRITORY_DATA_KEY = 'BOS_TERRITORY_DATA';