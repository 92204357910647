export const CONST_ACCOUNTS_AUDIT_TRAIL_TABLE_COLUMNS = [
    {
      title: 'Site',
      dataIndex: 'site',
      width: 80,
      blankWidth: 80,
      sorter: false,
      render: false,
    },
    {
      title: 'Account#',
      dataIndex: 'nationalId',
      width: 100,
      blankWidth: 100,
      sorter: false,
      render: false,
      extendRender: true,
    },
    {
      title: 'Log',
      dataIndex: 'log',
      width: 190,
      blankWidth: 130,
      sorter: false,
      render: false,
    },
    {
      title: 'Value Before Change',
      dataIndex: 'oldValue',
      width: 145,
      blankWidth: 145,
      sorter: false,
      render: false,
    },
    {
      title: 'Value After Change',
      dataIndex: 'newValue',
      width: 140,
      blankWidth: 140,
      sorter: false,
      render: false,
    },
    {
      title: 'Modified Log',
      dataIndex: 'userId',
      width: 200,
      blankWidth: 160,
      render: false,
      extendRender: true,
    },
  ];
  export const CONST_SUBS_AUDIT_TRAIL_TABLE_COLUMNS = [
    {
      title: 'Site',
      dataIndex: 'site',
      width: 60,
      blankWidth: 70,
      sorter: false,
      render: false,
    },
    {
      title: 'Account#',
      dataIndex: 'nationalId',
      width: 100,
      blankWidth: 100,
      sorter: false,
      render: false,
      extendRender: true,
    },
    {
      title: 'Original Item',
      dataIndex: 'originalItem',
      width: 150,
      blankWidth: 80,
      sorter: false,
      render: false,
    },
    {
      title: 'Original UOM',
      dataIndex: 'originalUom',
      width: 120,
      blankWidth: 120,
      sorter: false,
      render: false,
    },
    {
      title: 'Rule Type',
      dataIndex: 'ruleType',
      width: 100,
      blankWidth: 100,
      sorter: false,
      render: false,
    },
    {
      title: 'Sub Item',
      dataIndex: 'substituteItem',
      width: 120,
      blankWidth: 120,
      sorter: false,
      render: false,
    },
    {
      title: 'Log',
      dataIndex: 'log',
      width: 130,
      blankWidth: 130,
      sorter: false,
      render: false,
    },
    {
      title: 'Value Before Change',
      dataIndex: 'oldValue',
      width: 145,
      blankWidth: 145,
      sorter: false,
      render: false,
    },
    {
      title: 'Value After Change',
      dataIndex: 'newValue',
      width: 140,
      blankWidth: 140,
      sorter: false,
      render: false,
    },
    {
      title: 'Modified Log',
      dataIndex: 'userId',
      width: 200,
      blankWidth: 160,
      render: false,
      extendRender: true,
    },
  ];
