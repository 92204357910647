export const DASHBOARD_FETCH_ORG_INFO_BEGIN = 'DASHBOARD_FETCH_ORG_INFO_BEGIN';
export const DASHBOARD_FETCH_ORG_INFO_SUCCESS = 'DASHBOARD_FETCH_ORG_INFO_SUCCESS';
export const DASHBOARD_FETCH_ORG_INFO_FAILURE = 'DASHBOARD_FETCH_ORG_INFO_FAILURE';
export const DASHBOARD_FETCH_ORG_INFO_DISMISS_ERROR = 'DASHBOARD_FETCH_ORG_INFO_DISMISS_ERROR';
export const DASHBOARD_FETCH_TERRITORY_DATA_BEGIN = 'DASHBOARD_FETCH_TERRITORY_DATA_BEGIN';
export const DASHBOARD_FETCH_TERRITORY_DATA_SUCCESS = 'DASHBOARD_FETCH_TERRITORY_DATA_SUCCESS';
export const DASHBOARD_FETCH_TERRITORY_DATA_FAILURE = 'DASHBOARD_FETCH_TERRITORY_DATA_FAILURE';
export const DASHBOARD_FETCH_TERRITORY_DATA_DISMISS_ERROR = 'DASHBOARD_FETCH_TERRITORY_DATA_DISMISS_ERROR';
export const DASHBOARD_FETCH_SUMMARY_DATA_BEGIN = 'DASHBOARD_FETCH_SUMMARY_DATA_BEGIN';
export const DASHBOARD_FETCH_SUMMARY_DATA_SUCCESS = 'DASHBOARD_FETCH_SUMMARY_DATA_SUCCESS';
export const DASHBOARD_FETCH_SUMMARY_DATA_FAILURE = 'DASHBOARD_FETCH_SUMMARY_DATA_FAILURE';
export const DASHBOARD_FETCH_SUMMARY_DATA_DISMISS_ERROR = 'DASHBOARD_FETCH_SUMMARY_DATA_DISMISS_ERROR';
export const DASHBOARD_FETCH_NATIONAL_ACCOUNT_DATA_BEGIN = 'DASHBOARD_FETCH_NATIONAL_ACCOUNT_DATA_BEGIN';
export const DASHBOARD_FETCH_NATIONAL_ACCOUNT_DATA_SUCCESS = 'DASHBOARD_FETCH_NATIONAL_ACCOUNT_DATA_SUCCESS';
export const DASHBOARD_FETCH_NATIONAL_ACCOUNT_DATA_FAILURE = 'DASHBOARD_FETCH_NATIONAL_ACCOUNT_DATA_FAILURE';
export const DASHBOARD_FETCH_NATIONAL_ACCOUNT_DATA_DISMISS_ERROR = 'DASHBOARD_FETCH_NATIONAL_ACCOUNT_DATA_DISMISS_ERROR';
export const DASHBOARD_FETCH_CONCEPT_IDS_DATA_BEGIN = 'DASHBOARD_FETCH_CONCEPT_IDS_DATA_BEGIN';
export const DASHBOARD_FETCH_CONCEPT_IDS_DATA_SUCCESS = 'DASHBOARD_FETCH_CONCEPT_IDS_DATA_SUCCESS';
export const DASHBOARD_FETCH_CONCEPT_IDS_DATA_FAILURE = 'DASHBOARD_FETCH_CONCEPT_IDS_DATA_FAILURE';
export const DASHBOARD_FETCH_CONCEPT_IDS_DATA_DISMISS_ERROR = 'DASHBOARD_FETCH_CONCEPT_IDS_DATA_DISMISS_ERROR';
export const DASHBOARD_RESET_CONCEPT_IDS_DATA = 'DASHBOARD_RESET_CONCEPT_IDS_DATA';
export const DASHBOARD_RESET_ORDER_REPORT = 'DASHBOARD_RESET_ORDER_REPORT';
export const DASHBOARD_FETCH_ORDER_REPORT_DATA_BEGIN = 'DASHBOARD_FETCH_ORDER_REPORT_DATA_BEGIN';
export const DASHBOARD_FETCH_ORDER_REPORT_DATA_SUCCESS = 'DASHBOARD_FETCH_ORDER_REPORT_DATA_SUCCESS';
export const DASHBOARD_FETCH_ORDER_REPORT_DATA_FAILURE = 'DASHBOARD_FETCH_ORDER_REPORT_DATA_FAILURE';
export const DASHBOARD_FETCH_ORDER_REPORT_DATA_DISMISS_ERROR = 'DASHBOARD_FETCH_ORDER_REPORT_DATA_DISMISS_ERROR';
export const DASHBOARD_EXPORT_REPORT_DATA_BEGIN = 'DASHBOARD_EXPORT_REPORT_DATA_BEGIN';
export const DASHBOARD_EXPORT_REPORT_DATA_SUCCESS = 'DASHBOARD_EXPORT_REPORT_DATA_SUCCESS';
export const DASHBOARD_EXPORT_REPORT_DATA_FAILURE = 'DASHBOARD_EXPORT_REPORT_DATA_FAILURE';
export const DASHBOARD_EXPORT_REPORT_DATA_DISMISS_ERROR = 'DASHBOARD_EXPORT_REPORT_DATA_DISMISS_ERROR';
export const DASHBOARD_RESET_SUMMARY_DATA = 'DASHBOARD_RESET_SUMMARY_DATA';
export const DASHBOARD_SAVE_DASHBOARD_CONDITION_DATA = 'DASHBOARD_SAVE_DASHBOARD_CONDITION_DATA';
export const DASHBOARD_RESET_DASHBOARD_CONDITION_DATA = 'DASHBOARD_RESET_DASHBOARD_CONDITION_DATA';
export const DASHBOARD_SAVE_INIT_TERRITORY_FILTERS_DATA = 'DASHBOARD_SAVE_INIT_TERRITORY_FILTERS_DATA';
export const ERROR_CONTACT_ADMINISTRATOR = "Sorry, you don't have permission to access Sysco In Action. Please contact administrator.";
export const ERROR_SELECT_TERRITORY = 'pleaseSelectTerritory';
export const ERROR_CONTACT_ADMIN = 'There is an error. Please refresh page or contact administrator.';
export const DASHBOARD_SAVE_NOTIFICATION_STATUS = 'DASHBOARD_SAVE_NOTIFICATION_STATUS';
export const DASHBOARD_FETCH_DEFAULT_PASSWORD_BEGIN = 'DASHBOARD_FETCH_DEFAULT_PASSWORD_BEGIN';
export const DASHBOARD_FETCH_DEFAULT_PASSWORD_SUCCESS = 'DASHBOARD_FETCH_DEFAULT_PASSWORD_SUCCESS';
export const DASHBOARD_FETCH_DEFAULT_PASSWORD_FAILURE = 'DASHBOARD_FETCH_DEFAULT_PASSWORD_FAILURE';
export const DASHBOARD_FETCH_DEFAULT_PASSWORD_DISMISS_ERROR = 'DASHBOARD_FETCH_DEFAULT_PASSWORD_DISMISS_ERROR';
export const DASHBOARD_SAVE_DROP_DOWN_LIST = 'DASHBOARD_SAVE_DROP_DOWN_LIST';
export const DASHBOARD_FETCH_TERRITORY_BY_DISTRICT_BEGIN = 'DASHBOARD_FETCH_TERRITORY_BY_DISTRICT_BEGIN';
export const DASHBOARD_FETCH_TERRITORY_BY_DISTRICT_SUCCESS = 'DASHBOARD_FETCH_TERRITORY_BY_DISTRICT_SUCCESS';
export const DASHBOARD_FETCH_TERRITORY_BY_DISTRICT_FAILURE = 'DASHBOARD_FETCH_TERRITORY_BY_DISTRICT_FAILURE';
export const DASHBOARD_FETCH_TERRITORY_BY_DISTRICT_DISMISS_ERROR = 'DASHBOARD_FETCH_TERRITORY_BY_DISTRICT_DISMISS_ERROR';
export const DASHBOARD_RESET_TERRITORY_BY_DISTRICT_DATA = 'DASHBOARD_RESET_TERRITORY_BY_DISTRICT_DATA';
export const DASHBOARD_SET_DISTRICT_CHANGED = 'DASHBOARD_SET_DISTRICT_CHANGED';

