// Initial state is the place you define all initial values for the Redux store of the feature.
// In the 'standard' way, initialState is defined in reducers: http://redux.js.org/docs/basics/Reducers.html
// But when application grows, there will be multiple reducers files, it's not intuitive what data is managed by the whole store.
// So Rekit extracts the initial state definition into a separate module so that you can have
// a quick view about what data is used for the feature, at any time.

// NOTE: initialState constant is necessary so that Rekit could auto add initial state when creating async actions.
const initialState = {
  reportsTotals: 0,
  reportsData: null,
  fetchScInActionDataPending: false,
  fetchScInActionDataError: null,
  exportScInActionReportDataPending: false,
  exportScInActionReportDataError: null,
  reportsCustomerData: null,
  reportsCustomerTotals: 0,
  fetchScInActionCustomerDataPending: false,
  fetchScInActionCustomerDataError: null,
  reportsOrderData: null,
  reportsOrderTotals: 0,
  fetchScInActionOrderDataPending: false,
  fetchScInActionOrderDataError: null,
  exportScInActionCustomerReportPending: false,
  exportScInActionCustomerReportError: null,
  exportScInActionOrderReportPending: false,
  exportScInActionOrderReportError: null,
  fetchSummaryDataPending: false,
  fetchSummaryDataError: null,
  exportSummaryReportPending: false,
  exportSummaryReportError: null,
  fetchSummaryCustomerDataPending: false,
  fetchSummaryCustomerDataError: null,
  fetchSummaryOrderDataPending: false,
  fetchSummaryOrderDataError: null,
  exportSummaryCustomerReportPending: false,
  exportSummaryCustomerReportError: null,
  exportSummaryOrderReportPending: false,
  exportSummaryOrderReportError: null,
  fetchOrdersDataPending: false,
  fetchOrdersDataError: null,
  exportOrdersReportPending: false,
  exportOrdersReportError: null,
  summary: null,
  customerSummary: null,
  orderSummary:null,
  fetchOrdersReportByChannelTypePending: false,
  fetchOrdersReportByChannelTypeError: null,
  fetchSummaryDataFromChannelTypePending: false,
  fetchSummaryDataFromChannelTypeError: null,
  reportsDataAccount: null,
  reportsTotalsAccount: null,
  summaryAccount: null,
  reportsDataOrders: null,
  reportsTotalsOrders: null,
  summaryOrders:null,
  exportOrdersReportsByChannelTypePending: false,
  exportOrdersReportsByChannelTypeError: null,
  exportOrdersReportByChannelTypePending: false,
  exportOrdersReportByChannelTypeError: null,
  fetchOrderDetailDataPending: false,
  fetchOrderDetailDataError: null,
  fetchOrderDetailReportPending: false,
  fetchOrderDetailReportError: null,
  reportsOrderDetailData: null,
  reportsOrderDetailTotals: 0,
  orderDetailSummary: null,
  exportOrderDetailReportPending: false,
  exportOrderDetailReportError: null,
  fetchSummaryDataByRegionPending: false,
  fetchSummaryDataByRegionError: null,
  reportsRegionData: null,
  reportsRegionTotals: null,
  regionSummary: null,
  exportSummaryRegionReportPending: false,
  exportSummaryRegionReportError: null,
  fetchOosReportBySitePending: false,
  fetchOosReportBySiteError: null,
  fetchOosReportByCategoryPending: false,
  fetchOosReportByCategoryError: null,
  fetchOosReportByOrderNumberPending: false,
  fetchOosReportByOrderNumberError: null,
  exportOosReportBySitePending: false,
  exportOosReportBySiteError: null,
  exportOosReportByCategoryPending: false,
  exportOosReportByCategoryError: null,
  exportOosReportByOrderNumberPending: false,
  exportOosReportByOrderNumberError: null,
  fetchOosAccountAccountTypePending: false,
  fetchOosAccountAccountTypeError: null,
  fetchOosAccountCustomerPending: false,
  fetchOosAccountCustomerError: null,
  exportOosAccountAccountTypePending: false,
  exportOosAccountAccountTypeError: null,
  exportOosAccountCustomerPending: false,
  exportOosAccountCustomerError: null,
  fetchOosAccountOrderPending: false,
  fetchOosAccountOrderError: null,
  exportOosAccountOrderPending: false,
  exportOosAccountOrderError: null,
  fetchSummaryDataBySitePending: false,
  fetchSummaryDataBySiteError: null,
  exportSummaryDataBySitePending: false,
  exportSummaryDataBySiteError: null,
  reportsSiteData: null,
  reportsSiteTotals: null,
  siteSummary: null,
};

export default initialState;
