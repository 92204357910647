import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  REPORTS_RESET_SUMMARY_DATA_BY_SITE,
} from './constants';

export function resetSummaryDataBySite() {
  return {
    type: REPORTS_RESET_SUMMARY_DATA_BY_SITE,
  };
}

export function useResetSummaryDataBySite() {
  const dispatch = useDispatch();
  const boundAction = useCallback((...params) => dispatch(resetSummaryDataBySite(...params)), [dispatch]);
  return { resetSummaryDataBySite: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case REPORTS_RESET_SUMMARY_DATA_BY_SITE:
      return {
        ...state,
        reportsSiteData: null,
        reportsSiteTotals: null,
        siteSummary: null,
      };

    default:
      return state;
  }
}
