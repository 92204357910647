import moment from 'moment';
import { SLASH_DATE_FORMAT } from './constants';
export const CONST_OGM_SUBS_TABLE_PARENT_COLUMNS = [
  {
    title: 'Site ID',
    dataIndex: 'site',
    width: 100,
    blankWidth: 100,
    sorter: true,
    render: false,
    extendRender: true,
  },
  {
    title: 'Group/Ship To',
    dataIndex: 'nationalId',
    width: 160,
    blankWidth: 160,
    sorter: true,
    render: false,
    extendRender: true,
  },
  {
    title: 'Original Item',
    dataIndex: 'originalItem',
    width: 200,
    blankWidth: 200,
    sorter: true,
    render: false,
    extendRender: true,
  },
  {
    title: 'Sub Item',
    dataIndex: 'substituteItem',
    width: 200,
    blankWidth: 200,
    render: false,
    extendRender: true,
  },
  {
    title: 'Sub Ratio/Reason Code',
    dataIndex: 'subRatio',
    width: 180,
    blankWidth: 180,
    render: false,
    extendRender: true,
  },
  {
    title: 'End Date',
    dataIndex: 'endDate',
    width: 110,
    blankWidth: 110,
    render: value => {
      const expiredDate = value && moment().diff(moment(value), 'days'); //expired date will return positive number
      if(value && expiredDate < 1) {
        return moment(value).format(SLASH_DATE_FORMAT);
      } else if(value) {
        return 'EXPIRED';
      }
    },
  },
  {
    title: 'Last Order Date',
    dataIndex: 'lastOrderDate',
    width: 160,
    blankWidth: 160,
    render: value => {
      if(value) {
        return moment(value).format(SLASH_DATE_FORMAT);
      }
    },
  },
  {
    title: 'Modified',
    dataIndex: 'modifiedByName',
    width: 190,
    blankWidth: 160,
    render: false,
    extendRender: true,
  },
  {
    title: 'Cust APV',
    dataIndex: 'customerApprovalFlag',
    width: 100,
    blankWidth: 80,
    sorter: false,
    render: false,
    extendRender: true,
  },
];
export const CONST_OGM_SUBS_TABLE_EXPANDED_COLUMNS = [
  {
    title: 'Priority',
    dataIndex: 'subPriority',
    width: 80,
    blankWidth: 80,
    sorter: false,
    render: value => (value === null || value == '0') ? '-' : value,
  },
  {
    title: 'Sub Item',
    dataIndex: 'substituteItem',
    width: 200,
    blankWidth: 160,
    sorter: false,
    render: false,
    extendRender: true,
  },
  {
    title: 'Reason Code',
    dataIndex: 'reasonCode',
    width: 120,
    blankWidth: 120,
    sorter: false,
    render: false,
    extendRender: true,
  },
  {
    title: 'Sub Ratio',
    dataIndex: 'subRatio',
    width: 90,
    blankWidth: 90,
    sorter: false,
    render: false,
  },
  {
    title: 'End Date',
    dataIndex: 'endDate',
    width: 100,
    blankWidth: 100,
    sorter: false,
    render: value => {
      const expiredDate = value && moment().diff(moment(value), 'days'); //expired date will return positive number
      if(value && expiredDate < 1) {
        return moment(value).format(SLASH_DATE_FORMAT);
      } else if(value) {
        return 'EXPIRED';
      }
    },
  },
  {
    title: 'Last Modified',
    dataIndex: 'modifiedTimestamp',
    width: 130,
    blankWidth: 120,
    sorter: false,
    render: false,
    extendRender: true,
  },
  {
    title: 'Cust APV',
    dataIndex: 'customerApprovalFlag',
    width: 80,
    blankWidth: 80,
    sorter: false,
    render: false,
    extendRender: true,
  },
];