import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  MANAGE_SUBS_FETCH_OGM_SUBS_DATA_BEGIN,
  MANAGE_SUBS_FETCH_OGM_SUBS_DATA_SUCCESS,
  MANAGE_SUBS_FETCH_OGM_SUBS_DATA_FAILURE,
  MANAGE_SUBS_FETCH_OGM_SUBS_DATA_DISMISS_ERROR,
} from './constants';
import { API_REQUEST_URL } from '../../../common/apiConfig';
export function fetchOgmSubsData(args = {}) {
  return (dispatch,getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: MANAGE_SUBS_FETCH_OGM_SUBS_DATA_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const token = `Bearer ${cognitoIdToken}`;
      let conditions = args;
      if(args.sortedBy === undefined || args.sortedBy === null) {
        conditions = {
          ...conditions,
          sort: "descend",
          sortedBy: "nationalId",  
        }
      }
      const doRequest = axios.post(`${API_REQUEST_URL}/getOrderGuideSubsData`, conditions, {
        headers: {
          'Authorization': token,
        }
      });
      doRequest.then(
        (res) => {
          // const data = res.data.data.map((item) => {
          //   return {
          //     ...item,
          //     nationalId: item.nationalId === null ? "" : item.nationalId,
          //     nationalName: item.nationalName === null ? "" : item.nationalName,
          //     customerName: item.customerName === null ? "" : item.customerName,
          //     customerNumber: item.customerNumber === null ? "" : item.customerNumber,
          //   }
          // });
          const result = {...res.data};
          dispatch({
            type: MANAGE_SUBS_FETCH_OGM_SUBS_DATA_SUCCESS,
            data: result,
          });
          resolve(result);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          const errorMsg = err && err.response ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.';
          dispatch({
            type: MANAGE_SUBS_FETCH_OGM_SUBS_DATA_FAILURE,
            data: { error: errorMsg },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissFetchOgmSubsDataError() {
  return {
    type: MANAGE_SUBS_FETCH_OGM_SUBS_DATA_DISMISS_ERROR,
  };
}

export function useFetchOgmSubsData() {
  const dispatch = useDispatch();

  const { ogmSubsData, fetchOgmSubsDataPending, fetchOgmSubsDataError } = useSelector(
    state => ({
      ogmSubsData: state.manageSubs.ogmSubsData,
      fetchOgmSubsDataPending: state.manageSubs.fetchOgmSubsDataPending,
      fetchOgmSubsDataError: state.manageSubs.fetchOgmSubsDataError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(fetchOgmSubsData(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissFetchOgmSubsDataError());
  }, [dispatch]);

  return {
    ogmSubsData,
    fetchOgmSubsData: boundAction,
    fetchOgmSubsDataPending,
    fetchOgmSubsDataError,
    dismissFetchOgmSubsDataError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case MANAGE_SUBS_FETCH_OGM_SUBS_DATA_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchOgmSubsDataPending: true,
        fetchOgmSubsDataError: null,
      };

    case MANAGE_SUBS_FETCH_OGM_SUBS_DATA_SUCCESS:
      // The request is success
      return {
        ...state,
        ogmSubsData: action.data,
        fetchOgmSubsDataPending: false,
        fetchOgmSubsDataError: null,
      };

    case MANAGE_SUBS_FETCH_OGM_SUBS_DATA_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchOgmSubsDataPending: false,
        fetchOgmSubsDataError: action.data.error,
      };

    case MANAGE_SUBS_FETCH_OGM_SUBS_DATA_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        fetchOgmSubsDataError: null,
      };

    default:
      return state;
  }
}
